"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue2Dropzone = _interopRequireDefault(require("vue2-dropzone"));

require("vue2-dropzone/dist/vue2Dropzone.min.css");

var _vueRecaptchaV = require("vue-recaptcha-v3");

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

require("../../../../node_modules/pretty-checkbox/src/pretty-checkbox.scss");

var _arrowLinkButton = _interopRequireDefault(require("./common/arrow-link-button.vue"));

var _controlledTextarea = _interopRequireDefault(require("./daveyForm/controlled-textarea.vue"));

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
$(document).ready(function () {
  $('.vs__search').each(function () {
    $(this).attr('type', 'text');
  });
});

const buildInput = field => ({
  'name': field.field_data.input_name,
  'type': field.field_data.input_type,
  'id': field.field_data.input_id,
  'class': field.field_data.input_class,
  'placeholder': field.field_data.input_placeholder,
  'label': field.field_data.input_label,
  'validators': field.field_data.input_validation,
  'input_options': field.field_data.input_options,
  'error': false,
  'error_msg': ''
});

const buildValidations = field => ({
  'validation': field.field_data.input_validation,
  'args': {
    'max_length': field.field_data.input_max_length,
    'min_length': field.field_data.input_min_length
  }
});

var _default = {
  name: 'contact-form',
  components: {
    ArrowLink: _arrowLinkButton.default,
    vSelect: _vueSelect.default,
    ControlledTextarea: _controlledTextarea.default,
    vueDropzone: _vue2Dropzone.default,
    'vue-recaptcha': _vueRecaptchaV.VueReCaptcha
  },

  data() {
    return {
      summary: true,
      form: {},
      fields: {},
      form_sections: [],
      field_validations: {},
      selectFieldState: {},
      submitStatus: false,
      dropzoneOptions: {
        url: window.location.origin + '/api/upload-to-aws',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        autoProcessQueue: true,
        paramName: "upload",
        uploadMultiple: false
      },
      uploadedFileUri: ""
    };
  },

  props: {
    actionUrl: {
      type: String,
      default: ''
    },
    formFields: {
      type: Array,
      default: []
    },
    formName: {
      type: String,
      default: ''
    },
    formClass: {
      type: String,
      default: ''
    },
    formId: {
      type: String,
      default: ''
    },
    readmore: {
      type: String,
      default: ''
    },
    readless: {
      type: String,
      default: ''
    },
    dragdrop: {
      type: String,
      default: ''
    },
    selectfilefromcomputer: {
      type: String,
      default: ''
    },
    submitbutton: {
      type: String,
      default: ''
    }
  },
  mounted: function () {
    this.getFieldData();
  },

  beforeMount() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptcha("login").then(token => {
        this.recaptchatoken = token;
        document.getElementById('g-recaptcha-response').value = token;
        console.log("GooGle Token:" + token);
      });
    });
  },

  watch: {
    form: {
      handler: function (val, oldVal) {
        // console.log(val,oldVal);
        this.checkValidation();
      },
      deep: true
    }
  },
  filters: {},
  methods: {
    onComplete(response) {
      const parsedResponse = JSON.parse(response.xhr.response);
      this.uploadedFileUri = parsedResponse.status === 200 ? this.uploadedFileUri + '|' + parsedResponse.uri : "There was a problem uploading the file :S";
    },

    getfieldLength(name) {
      return this.form && this.form[name] && this.form[name].length;
    },

    trunc(str, len = 240) {
      _lodash.default.trim(str);

      return _lodash.default.truncate(str, {
        'length': len
      });
    },

    getFieldData() {
      this.formFields.forEach(field => {
        if (field.type == 'formInputDual') {
          let input1 = {
            'name': field.field_data.input_name_col1,
            'type': field.field_data.input_type_col1,
            'id': field.field_data.input_id_col1,
            'class': field.field_data.input_class_col1,
            'placeholder': field.field_data.input_placeholder_col1,
            'label': field.field_data.input_label_col1,
            'validators': field.field_data.input_validation_col1,
            'error': false,
            'error_msg': ''
          };
          this.form[field.field_data.input_name_col1] = input1.type == 'checkbox' ? false : '';
          this.fields[field.field_data.input_name_col1] = input1;
          this.field_validations[field.field_data.input_name_col1] = {
            'validation': field.field_data.input_validation_col1,
            'args': {
              'max_length': field.field_data.input_max_length_col1,
              'min_length': field.field_data.input_min_length_col1
            }
          };
          let input2 = {
            'name': field.field_data.input_name_col2,
            'type': field.field_data.input_type_col2,
            'id': field.field_data.input_id_col2,
            'class': field.field_data.input_class_col2,
            'placeholder': field.field_data.input_placeholder_col2,
            'label': field.field_data.input_label_col2,
            'validators': field.field_data.input_validation_col2,
            'error': false,
            'error_msg': ''
          };
          this.form[field.field_data.input_name_col2] = input2.type == 'checkbox' ? false : '';
          this.fields[field.field_data.input_name_col2] = input2;
          this.field_validations[field.field_data.input_name_col2] = {
            'validation': field.field_data.input_validation_col2,
            'args': {
              'max_length': field.field_data.input_max_length_col2,
              'min_length': field.field_data.input_min_length_col2
            }
          };
          this.form_sections.push([input1, input2]);
        } else if (field.type == 'formSelectField') {
          let input = buildInput(field);

          if (input.type == 'radio') {
            this.form[field.field_data.input_name] = input.type === 'radio' ? false : '';
            this.fields[field.field_data.input_name] = input;
            this.field_validations[field.field_data.input_name] = buildValidations(field);
            this.form_sections.push([input]);
          } else {
            const options = [];
            field.field_data.input_options.forEach(option => options.push({
              value: option[0],
              label: option[1]
            }));
            const input1 = {
              'name': field.field_data.input_name,
              'type': field.field_data.input_type,
              'id': field.field_data.input_id,
              'class': field.field_data.input_class,
              'options': options,
              'order': field.field_data.order,
              'label': field.field_data.input_label,
              'validators': field.field_data.input_validation,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name] = '';
            this.fields[field.field_data.input_name] = input1;
            this.field_validations[field.field_data.input_name] = {
              'validation': field.field_data.input_validation
            };
            this.form_sections.push([input1]);
          }
        } else if (field.type == 'formInputField') {
          let input = buildInput(field);
          this.form[field.field_data.input_name] = input.type === 'checkbox' ? false : '';
          this.fields[field.field_data.input_name] = input;
          this.field_validations[field.field_data.input_name] = buildValidations(field);
          this.form_sections.push([input]);
        } else if (field.type == 'formInputSelectDual') {
          if (field.field_data.input_type_col1 == 'radio') {
            let input = {
              'name': field.field_data.input_name_col1,
              'type': field.field_data.input_type_col1,
              'id': field.field_data.input_id_col1,
              'class': field.field_data.input_class_col1,
              'input_options': field.field_data.input_options_col1,
              'order': field.field_data.order_col1,
              'label': field.field_data.input_label_col1,
              'validators': field.field_data.input_validation_col1,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name] = input.type === 'checkbox' ? false : '';
            this.fields[field.field_data.input_name] = input;
            this.field_validations[field.field_data.input_name] = buildValidations(field);
            const input2 = {
              'name': field.field_data.input_name_col2,
              'type': field.field_data.input_type_col2,
              'id': field.field_data.input_id_col2,
              'class': field.field_data.input_class_col2,
              'placeholder': field.field_data.input_placeholder_col2,
              'order': field.field_data.order_col2,
              'label': field.field_data.input_label_col2,
              'validators': field.field_data.input_validation_col2,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name_col2] = input2.type == 'checkbox' ? false : '';
            ;
            this.fields[field.field_data.input_name_col2] = input2;
            this.field_validations[field.field_data.input_name_col2] = {
              'validation': field.field_data.input_validation_col2,
              'args': {
                'max_length': field.field_data.input_max_length_col2,
                'min_length': field.field_data.input_min_length_col2
              }
            };
            this.form_sections.push([input, input2]);
          } else {
            const options = [];
            field.field_data.input_options_col1.forEach(option => options.push({
              value: option[0],
              label: option[1]
            }));
            const input1 = {
              'name': field.field_data.input_name_col1,
              'type': field.field_data.input_type_col1,
              'id': field.field_data.input_id_col1,
              'class': field.field_data.input_class_col1,
              'options': options,
              'order': field.field_data.order_col1,
              'label': field.field_data.input_label_col1,
              'validators': field.field_data.input_validation_col1,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name_col1] = '';
            this.fields[field.field_data.input_name_col1] = input1;
            this.field_validations[field.field_data.input_name_col1] = {
              'validation': field.field_data.input_validation_col1
            };
            const input2 = {
              'name': field.field_data.input_name_col2,
              'type': field.field_data.input_type_col2,
              'id': field.field_data.input_id_col2,
              'class': field.field_data.input_class_col2,
              'placeholder': field.field_data.input_placeholder_col2,
              'order': field.field_data.order_col2,
              'label': field.field_data.input_label_col2,
              'validators': field.field_data.input_validation_col2,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name_col2] = input2.type == 'checkbox' ? false : '';
            ;
            this.fields[field.field_data.input_name_col2] = input2;
            this.field_validations[field.field_data.input_name_col2] = {
              'validation': field.field_data.input_validation_col2,
              'args': {
                'max_length': field.field_data.input_max_length_col2,
                'min_length': field.field_data.input_min_length_col2
              }
            };
            if (input1.order > input2.order) this.form_sections.push([input2, input1]);else this.form_sections.push([input1, input2]);
          }
        } else if (field.type == 'formSelectDual') {
          if (field.field_data.input_type_col1 == 'radio') {
            let input = {
              'name': field.field_data.input_name_col1,
              'type': field.field_data.input_type_col1,
              'id': field.field_data.input_id_col1,
              'class': field.field_data.input_class_col1,
              'input_options': field.field_data.input_options_col1,
              'order': field.field_data.order_col1,
              'label': field.field_data.input_label_col1,
              'validators': field.field_data.input_validation_col1
            };
            this.form[field.field_data.input_name] = input.type === 'checkbox' ? false : '';
            this.fields[field.field_data.input_name] = input;
            this.field_validations[field.field_data.input_name] = buildValidations(field);
            let input2 = {
              'name': field.field_data.input_name_col2,
              'type': field.field_data.input_type_col2,
              'id': field.field_data.input_id_col2,
              'class': field.field_data.input_class_col2,
              'input_options': field.field_data.input_options_col2,
              'order': field.field_data.order_col2,
              'label': field.field_data.input_label_col2,
              'validators': field.field_data.input_validation_col2
            };
            this.form[field.field_data.input_name] = input2.type === 'checkbox' ? false : '';
            this.fields[field.field_data.input_name] = input2; //this.field_validations[field.field_data.input_name] = buildValidations(field);

            this.form_sections.push([input, input2]);
          } else {
            const options1 = []; //console.log("Input options",field.field_data);

            field.field_data.input_options_col1.forEach(option => options1.push({
              value: option[0],
              label: option[1]
            }));
            const input1 = {
              'name': field.field_data.input_name_col1,
              'type': field.field_data.input_type_col1,
              'id': field.field_data.input_id_col1,
              'class': field.field_data.input_class_col1,
              'options': options1,
              'order': field.field_data.order_col1,
              'label': field.field_data.input_label_col1,
              'validators': field.field_data.input_validation_col1,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name_col1] = '';
            this.fields[field.field_data.input_name_col1] = input1;
            this.field_validations[field.field_data.input_name_col1] = {
              'validation': field.field_data.input_validation_col1
            };
            const options2 = [];
            field.field_data.input_options_col2.forEach(option => options2.push({
              value: option[0],
              label: option[1]
            }));
            let input2 = {
              'name': field.field_data.input_name_col2,
              'type': field.field_data.input_type_col2,
              'id': field.field_data.input_id_col2,
              'class': field.field_data.input_class_col2,
              'options': options2,
              'order': field.field_data.order_col2,
              'label': field.field_data.input_label_col2,
              'validators': field.field_data.input_validation_col2,
              'error': false,
              'error_msg': ''
            };
            this.form[field.field_data.input_name_col2] = '';
            this.fields[field.field_data.input_name_col2] = input2;
            this.field_validations[field.field_data.input_name_col2] = {
              'validation': field.field_data.input_validation_col2
            }; //console.log(input1,input2);

            if (input1.order > input2.order) this.form_sections.push([input2, input1]);else this.form_sections.push([input1, input2]);
          }
        } else if (field.type === "formUploadField") {
          const name = field.field_data.input_name;
          const label = field.field_data.input_label;
          const input = {
            'name': name,
            'type': 'upload',
            'label': label
          };
          this.form[name] = "";
          this.fields[name] = input;
          this.form_sections.push([input]);
        }
      });
    },

    checkValidation: function () {
      let valid = true;

      if (this.field_validations) {
        for (var field in this.field_validations) {
          let validations = this.field_validations[field].validation;
          if (validations === null || validations === undefined) continue;
          let status = true;

          if (validations.includes('required')) {
            if (!this.form[field]) {
              this.fields[field].error_msg = 'This field is required';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (status && validations.includes('numeric')) {
            if (!parseInt(this.form[field])) {
              this.fields[field].error_msg = 'Value should be a number';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (status && validations.includes('email')) {
            // console.log(this.form[field].includes('@'));
            if (!this.form[field].includes('@')) {
              this.fields[field].error_msg = 'Value should be a valid email';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (status && validations.includes('minLength')) {
            if (this.field_validations[field].args.min_length && this.form[field].length < this.field_validations[field].args.min_length) {
              this.fields[field].error_msg = 'Value should contain atleast ' + this.field_validations[field].args.min_length + ' letters/digits';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (status && validations.includes('maxLength')) {
            if (this.field_validations[field].args.max_length && this.form[field].length > this.field_validations[field].args.max_length) {
              this.fields[field].error_msg = 'Value can only contain ' + this.field_validations[field].args.max_length + ' letters/digits';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (status && validations.includes('checked')) {
            if (!this.form[field]) {
              this.fields[field].error_msg = 'This field should be checked!';
              this.fields[field].error = true;
              status = false;
            } else {
              this.fields[field].error_msg = '';
              this.fields[field].error = false;
            }
          }

          if (valid) valid = status;
        }
      }

      return valid;
    },

    onCaptchaVerified(recaptchaToken) {
      $('#g-recaptcha-response-form').val(recaptchaToken);
      console.log("Recaptch = ", recaptchaToken);
    },

    /**
     * Handler for submit
     */
    onSubmit: _lodash.default.debounce(function () {
      if (!this.checkValidation()) {
        this.submitStatus = 'ERROR';
        this.$forceUpdate();
        let error = false;

        _lodash.default.map(this.fields, (value, key) => {
          if (key != null && key != 'undefined') {
            if (value.error && !error) {
              error = true;
              console.log(key);
              var target = $('#formId');
              $('html, body').animate({
                scrollTop: target.offset().top - 100
              }, 500);
              $("input[name='" + key + "']").focus();
            }
          }
        });
      } else {
        _lodash.default.map(this.fields, (value, key) => {
          if (key != null || key != 'undefined') {
            if (value.type === "standardSelect") {
              let elem = document.querySelector(`input[name="${key}"]`);
              elem.parentNode.removeChild(elem);
              const input = document.createElement("input");
              input.setAttribute("type", "hidden");
              input.setAttribute("name", key);
              input.setAttribute("value", this.form[key]);
              this.$refs.contactForm.appendChild(input);
            }
          }
        }); // console.log("this.$refs.contactForm", this.$refs.contactForm);				


        window.contactform = this.$refs.contactForm;
        this.$refs.contactForm.submit();
      }
    }, 1000)
  }
};
exports.default = _default;