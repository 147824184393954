"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

var _utils = require("../utils.js");

var _paginateResults = _interopRequireDefault(require("./paginate-results.vue"));

var _dealerCard = _interopRequireDefault(require("./dealers/dealer-card.vue"));

var _arrowRight = _interopRequireDefault(require("./common/arrow-right.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
const typeValues = {
  "Premium Master Dealer": 35,
  "Master Dealer": 25,
  "Distributor": 10,
  "Standard": 5
};

const getTypeValue = value => (0, _lodash.has)(typeValues, value) ? typeValues[value] : -25;

var _default = {
  components: {
    ArrowRight: _arrowRight.default,
    DealerCard: _dealerCard.default,
    vSelect: _vueSelect.default
  },
  extends: _paginateResults.default,

  data() {
    return {
      productTypeSelected: false,
      filterLocation: {},
      filterRadius: 50,
      gmap_key: this.$store.state.Gmap_key,
      styles: _utils.mapStyles
    };
  },

  props: {
    defaultLocation: {
      type: Object,
      default: function () {
        return {
          'lat': -37.871,
          'lng': 144.971
        };
      }
    },
    defaultRadius: {
      type: Number,
      default: 25
    },
    translations: Object
  },
  computed: {
    radiusOptions: function () {
      return [{
        value: 10,
        name: this.isUS ? this.translations.r10us : this.translations.r10
      }, {
        value: 25,
        name: this.isUS ? this.translations.r25us : this.translations.r25
      }, {
        value: 50,
        name: this.isUS ? this.translations.r50us : this.translations.r50
      }, {
        value: 100,
        name: this.isUS ? this.translations.r100us : this.translations.r100
      }, {
        value: 1000000000,
        name: this.isUS ? this.translations.r100pus : this.translations.r100p
      }];
    },
    productTypeOptions: function () {
      return this.listingFilters.productTypes;
    },
    zoom: function () {
      if (this.filterRadius <= 10) return 13;else if (this.filterRadius <= 25) return 11;else if (this.filterRadius <= 50) return 10;else if (this.filterRadius <= 500) return 6;else return 4;
    },
    listingsCount: function () {
      return this.filteredDealers.length;
    },
    filteredDealers: function () {
      var self = this;
      var returnListings = [];
      self.filteredListings.forEach(function (val, key) {
        let dist = self.checkDistance(val);
        val.position.dist = dist;
        if (dist < self.filterRadius) returnListings.push(val);
      });
      if (this.productTypeSelected) returnListings = this.filterByType(returnListings, this.productTypeSelected);
      returnListings = returnListings.sort(function (x, y) {
        return x.position.dist - y.position.dist;
      });

      if (this.isAUNZ) {
        returnListings = returnListings.sort(function (x, y) {
          // console.log(x.type, y.type, getTypeValue(x.type), getTypeValue(y.type))
          if (getTypeValue(x.type) < getTypeValue(y.type)) {
            return 1;
          }

          if (getTypeValue(x.type) > getTypeValue(y.type)) {
            return -1;
          }

          return 0;
        });
      }

      return returnListings;
    },
    pageCount: function () {
      var self = this;
      return Math.round(self.filteredDealers.length / self.itemCount);
    },
    isUS: function () {
      return this.urlCountryPrefix === "/us/";
    },
    // isAU: function(){ return this.urlCountryPrefix === "/au/" },
    isAUNZ: function () {
      return this.urlCountryPrefix === "/au/" || this.urlCountryPrefix === "/nz/";
    }
  },
  created: function () {
    // if(this.isUS) {
    // 	this.filterRadius = this.kilometersToMiles(50);
    // }
    this.filterRadius = this.defaultRadius;
    this.filterLocation = this.defaultLocation;
    this.geolocation();
    window.addEventListener('load', () => this.handleResize(), false);
    window.addEventListener('resize', (0, _lodash.debounce)(() => this.handleResize(), 150));
  },
  methods: {
    filterByType: (collection, filter) => filter ? collection.filter(r => r.productTypes.includes(filter)) : collection,
    kilometersToMiles: value => (value / 1.609).toFixed(1),

    handleResize() {
      const targets = ['.c-dealer-item__title', '.c-dealer-item__details', '.c-dealer-item__service-types'];
      targets.forEach(target => this.resizeTargetsByClass(target));
    },

    resizeTargetsByClass(targetClass) {
      const targets = document.querySelectorAll(targetClass); // Reset Heights

      targets.forEach(target => {
        target.style.height = 'initial';
      }); // Match Heights

      let tallest = 0;
      targets.forEach(target => {
        const height = target.offsetHeight;
        if (height > tallest) tallest = height;
      });
      targets.forEach(target => {
        target.style.height = `${tallest}px`;
      });
    },

    buildURI(url) {
      if (url.indexOf("//") > -1) return url;
      if (url && url.length && url.length > 0) return `http://${url}`;
      return null;
    },

    setPlace(place) {
      if (!place) return;
      this.filterLocation = {
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
    },

    geolocation: function () {
      let userLoc = {};
      if (Object.keys(userLoc).length === 0) userLoc = this.defaultLocation;
      var self = this;
      this.$gmapApiPromiseLazy().then(() => {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'location': userLoc
        }, function (results, status) {
          if (status === 'OK') {
            // console.log(results);
            if (results[0]) {
              self.filterLocation = {
                address: results[0].formatted_address,
                lat: userLoc.lat,
                lng: userLoc.lng
              };
            } else {
              self.filterLocation = {
                address: '',
                lat: userLoc.lat,
                lng: userLoc.lng
              };
            }
          } else {// console.log('Geocoder failed due to: ' + status);
          }
        });
      });
      navigator.geolocation.getCurrentPosition(position => {
        userLoc = {
          'lat': position.coords.latitude,
          'lng': position.coords.longitude
        };
        var self = this;
        this.$gmapApiPromiseLazy().then(() => {
          let geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            'location': userLoc
          }, function (results, status) {
            if (status === 'OK') {
              // console.log(results);
              if (results[0]) {
                self.filterLocation = {
                  address: results[0].formatted_address,
                  lat: userLoc.lat,
                  lng: userLoc.lng
                };
              } else {
                self.filterLocation = {
                  address: '',
                  lat: userLoc.lat,
                  lng: userLoc.lng
                };
              }
            } else {// console.log('Geocoder failed due to: ' + status);
            }
          });
        });
      });
    },
    checkDistance: function (dealer) {
      let lat1 = dealer.position.lat;
      var lat2 = this.filterLocation.lat;
      let lon1 = dealer.position.lng;
      var lon2 = this.filterLocation.lng;

      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
          dist = 1;
        }

        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return Math.round(dist);
      }
    }
  }
};
exports.default = _default;