"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("../utils.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ['listing'],

  data() {
    return {
      gmap_key: this.$store.state.Gmap_key,
      styles: _utils.mapStyles
    };
  },

  computed: {},
  methods: {},
  created: function () {}
};
exports.default = _default;