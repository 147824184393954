"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _arrowRight = _interopRequireDefault(require("../common/arrow-right.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowRight: _arrowRight.default
  },
  props: {
    href: String,
    linkTitle: String,
    imageSource: String,
    imageAlt: String,
    listingTitle: String,
    listingShortDescription: String,
    viewProductText: {
      type: String,
      default: 'View Product'
    }
  }
};
exports.default = _default;