"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'arrow-link',
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'yellow'
    }
  }
};
exports.default = _default;