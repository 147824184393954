"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

var _vueAutosuggest = require("vue-autosuggest");

var _arrowRight = _interopRequireDefault(require("./common/arrow-right.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowRight: _arrowRight.default,
    vSelect: _vueSelect.default,
    VueAutosuggest: _vueAutosuggest.VueAutosuggest
  },

  data() {
    return {
      query: "",
      selected: "",
      documentTypeSelected: null,
      productTypeSelected: null,
      productApplicationSelected: null,
      filteredListings: [],
      filterFlags: [],
      page: 1,
      searchInputProps: {
        id: "autosuggest__input",
        placeholder: "Enter Search Keyword"
      }
    };
  },

  props: {
    urlCountryPrefix: {
      type: String,
      default: ''
    },
    itemCount: {
      type: Number,
      default: 10
    },
    listings: Array,
    filters: Object,
    productTypes: Array,
    productApplications: Array,
    doctypes: Object,
    translations: Object
  },
  mounted: function () {
    this.renderPage(this.page);
    this.filteredListings = this.listings;
  },
  computed: {
    filteredOptions() {
      return [{
        data: this.filteredByQuery
      }];
    },

    documentTypesAsArray() {
      return Object.values(this.doctypes);
    },

    filteredResults() {
      let filteredResults = this.filteredByQuery;
      if (this.documentTypeSelected) filteredResults = this.filterByDocumentType(filteredResults, this.documentTypeSelected);
      if (this.productTypeSelected) filteredResults = this.filterByType(filteredResults, this.productTypeSelected);
      if (this.productApplicationSelected) filteredResults = this.filterByApplication(filteredResults, this.productApplicationSelected);
      return filteredResults;
    },

    filteredByQuery() {
      if (this.searchStringIsValid) {
        return this.listings && this.listings.filter(result => {
          if (result.queryString) {
            return result.queryString.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          } else {
            return false;
          }
        });
      } else {
        return this.listings;
      }
    },

    listingsLength() {
      return this.filteredResults.length;
    },

    searchStringIsValid() {
      return this.query && this.query !== "" && this.query.length > 0;
    },

    displayStart() {
      return (this.page - 1) * this.itemCount;
    },

    displayEnd() {
      return this.displayStart + this.itemCount;
    },

    listingFilters() {
      var filters = Object.assign({}, this.filters);
      delete filters.filter_operator;
      return filters;
    },

    filterOperator() {
      return this.filters.filter_operator;
    },

    pageCount() {
      return Math.round(this.filteredResults.length / this.itemCount);
    },

    shouldDisplayResults() {
      return this.listingsLength > 0;
    }

  },
  methods: {
    filterByDocumentType: (collection, filter) => filter ? collection.filter(r => {
      if (r.documents.length > 0) {
        let shouldReturnTrue = false;
        r.documents.forEach(document => {
          if (document.type === filter) shouldReturnTrue = true;
        });
        return shouldReturnTrue;
      }

      return false;
    }) : collection,
    filterByType: (collection, filter) => filter ? collection.filter(r => {
      if (r.productTypes) {
        return r.productTypes.includes(filter);
      }

      return false;
    }) : collection,
    filterByApplication: (collection, filter) => filter ? collection.filter(r => {
      if (r.productApplications) {
        return r.productApplications.includes(filter);
      }

      return false;
    }) : collection,
    renderPage: function (pageNum) {
      window.scrollTo(0, 0);
      this.page = pageNum;
    },

    clickHandler(item) {
      this.resetPagination();
    },

    onSelected(item) {
      if (item) {
        this.selected = item.item;
        this.query = item.item.queryString;
      }
    },

    onInputChange(text) {
      this.resetPagination();
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.queryString;
    },

    focusMe(e) {
      this.resetPagination();
    },

    formatSuggestion(text) {
      const regExed = text.replace(new RegExp(this.query, "gi"), '<strong>$&</strong>');
      return regExed;
    },

    resetPagination() {
      this.page = 1;
    }

  },
  watch: {
    productTypeSelected: function () {
      this.resetPagination();
    },
    productApplicationSelected: function () {
      this.resetPagination();
    },
    documentTypeSelected: function () {
      this.resetPagination();
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
exports.default = _default;