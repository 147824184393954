"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default
  },

  data() {
    return {
      filteredListings: [],
      filterFlags: [],
      page: 1
    };
  },

  props: {
    urlCountryPrefix: {
      type: String,
      default: ''
    },
    itemCount: {
      type: Number,
      default: 10
    },
    listings: Array,
    filters: Object,
    translations: Object
  },
  mounted: function () {
    this.renderPage(this.page);
    this.filteredListings = this.listings;
    var self = this;
    Object.entries(self.listingFilters).forEach(function (filter, key) {
      filter[1].forEach(function (option, index) {
        if (option.checked) self.filterListings(filter[0], option.id, false);
      });
    });
  },
  computed: {
    displayStart: function () {
      return (this.page - 1) * this.itemCount;
    },
    displayEnd: function () {
      return this.displayStart + this.itemCount;
    },
    listingFilters: function () {
      var filters = Object.assign({}, this.filters);
      delete filters.filter_operator;
      return filters;
    },
    filterOperator: function () {
      return this.filters.filter_operator;
    },
    pageCount: function () {
      return Math.ceil(this.filteredListings.length / this.itemCount);
    }
  },
  methods: {
    renderPage: function (pageNum) {
      window.scrollTo(0, 0);
      this.page = pageNum;
    },
    filterListings: function (filter, value) {
      var self = this;

      if (value == -1) {
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) self.filterFlags.splice(key, 1);
        });
      } else {
        var push = true;
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) {
            self.filterFlags[key].value = value;
            push = false;
          }
        });
        if (push) self.filterFlags.push({
          'name': filter,
          'value': value
        });
      }

      var i = self.listings.length;
      self.filteredListings = [];
      var listings = self.listings.slice().reverse();
      if (self.filterFlags.length > 0) while (i--) {
        var push = false;

        if (this.filterOperator == "AND") {
          //console.log('record',listings[i].key,'\n');
          self.filterFlags.every(function (flag, key) {
            push = false; //console.log('filter',flag.name,'value',flag.value,'\n');

            if (listings[i][flag.name] != undefined) {
              if (Array.isArray(listings[i][flag.name])) listings[i][flag.name].forEach(function (val, key) {
                if (val == flag.value) {
                  push = true;
                }
              });
            } //console.log('push',push,'\n');


            if (!push) return false;else return true;
          });
        } else {
          self.filterFlags.forEach(function (flag, key) {
            if (listings[i][flag.name] != undefined) {
              if (Array.isArray(listings[i][flag.name])) listings[i][flag.name].forEach(function (val, key) {
                if (val == flag.value) push = true;
              });
            }
          });
        }

        if (push) self.filteredListings.push(listings[i]);
      } else self.filteredListings = self.listings;
      this.page = 1;
    }
  },
  watch: {
    displayStart: function (val) {//console.log('start:',val);
    },
    displayEnd: function (val) {//console.log('end:',val); 
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    trunc(str, len = 80) {
      _.trim(str);

      return _.truncate(str, {
        'length': len
      });
    }

  }
};
exports.default = _default;