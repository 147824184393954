"use strict";

require("core-js");

var _vueCommon = _interopRequireDefault(require("vue/dist/vue.common.js"));

var _vuex = _interopRequireDefault(require("vuex"));

var _vueCookies = _interopRequireDefault(require("vue-cookies"));

var _vueRecaptchaV = require("vue-recaptcha-v3");

var _vuejsPaginate = _interopRequireDefault(require("vuejs-paginate"));

var _paginateResults = _interopRequireDefault(require("./components/paginate-results.vue"));

var _paginateResultsNews = _interopRequireDefault(require("./components/paginate-results-news.vue"));

var _paginateResultsCaseStudies = _interopRequireDefault(require("./components/paginate-results-case-studies.vue"));

var _productLanding = _interopRequireDefault(require("./components/product-landing.vue"));

var _productWarranty = _interopRequireDefault(require("./components/product-warranty.vue"));

var _regionSelect = _interopRequireDefault(require("./components/region-select.vue"));

var _paginateResultsResources = _interopRequireDefault(require("./components/paginate-results-resources.vue"));

var _hotspots = _interopRequireDefault(require("./components/hotspots.vue"));

var VueGoogleMaps = _interopRequireWildcard(require("vue2-google-maps"));

var _cookieNotice = _interopRequireDefault(require("./components/cookie-notice.vue"));

var _dealers = _interopRequireDefault(require("./components/dealers.vue"));

var _dealerDetailMap = _interopRequireDefault(require("./components/dealerDetailMap.vue"));

var _findAProduct = _interopRequireDefault(require("./components/findAProduct.vue"));

var _findAProductPressureSystems = _interopRequireDefault(require("./components/find-a-product-pressure-systems.vue"));

var _search = _interopRequireDefault(require("./components/search.vue"));

var _vueAutosuggest = _interopRequireDefault(require("vue-autosuggest"));

var _navSearch = _interopRequireDefault(require("./components/nav-search.vue"));

var _daveyForm = _interopRequireDefault(require("./components/davey-form.vue"));

var _vueResource = _interopRequireDefault(require("vue-resource"));

var _prettyCheckboxVue = _interopRequireDefault(require("pretty-checkbox-vue"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vueCommon.default.use(_vuex.default); //the following siteKey is the public key given out on each recatpcha request,
//storing this in the repo is ok, however storing the secret key is not.


if (document.getElementsByClassName('davey-form-app').length > 0) {
  _vueCommon.default.use(_vueRecaptchaV.VueReCaptcha, {
    siteKey: '6Lf_WOAUAAAAAFvxo7zVo7JBu1Bkkm0JfZGYeYt2'
  });
}

_vueCommon.default.use(_vueCookies.default); // set default config


_vueCookies.default.config('7d');

_vueCommon.default.component("paginate", _vuejsPaginate.default);

_vueCommon.default.component("paginateResults", _paginateResults.default);

_vueCommon.default.component("paginateResultsNews", _paginateResultsNews.default);

_vueCommon.default.component("paginateResultsCaseStudies", _paginateResultsCaseStudies.default);

_vueCommon.default.component("productLanding", _productLanding.default);

_vueCommon.default.component("productWarranty", _productWarranty.default);

_vueCommon.default.component("regionSelect", _regionSelect.default);

_vueCommon.default.component("paginateResultsResources", _paginateResultsResources.default);

_vueCommon.default.component("hotspots", _hotspots.default);

const state = {
  Gmap_key: "AIzaSyAXcKUGpmRliwC6hQD_o98TMwxPuSoX1GQ"
};
const store = new _vuex.default.Store({
  state
});
const isoEl = document.querySelector('meta[name="ISO-3166-1"]');

if (document.getElementById("app")) {
  _vueCommon.default.use(VueGoogleMaps, {
    load: {
      region: isoEl.content,
      key: state.Gmap_key,
      v: "3.38",
      libraries: "places"
    },
    installComponents: true
  });
}

_vueCommon.default.component("google-map", VueGoogleMaps.Map);

_vueCommon.default.component("google-map-marker", VueGoogleMaps.Marker);

_vueCommon.default.component("ground-overlay", VueGoogleMaps.MapElementFactory({
  mappedProps: {
    opacity: {}
  },
  props: {
    source: {
      type: String
    },
    bounds: {
      type: Object
    }
  },
  events: ["click", "dblclick"],
  name: "groundOverlay",
  ctr: () => google.maps.GroundOverlay,
  ctrArgs: (options, {
    source,
    bounds
  }) => [source, bounds, options]
}));

_vueCommon.default.component("cookieNotice", _cookieNotice.default);

_vueCommon.default.component("dealers", _dealers.default);

_vueCommon.default.component("dealerDetailMap", _dealerDetailMap.default);

_vueCommon.default.component("findAProduct", _findAProduct.default);

_vueCommon.default.component('findAProductPressureSystems', _findAProductPressureSystems.default);

_vueCommon.default.component("search", _search.default);

_vueCommon.default.use(_vueAutosuggest.default);

_vueCommon.default.component("navSearch", _navSearch.default);

_vueCommon.default.component("daveyForm", _daveyForm.default);

_vueCommon.default.use(_vueResource.default);

_vueCommon.default.use(_prettyCheckboxVue.default);

if (document.getElementById('app')) {
  window.mustard = new _vueCommon.default({
    el: "#app",
    store,
    data: {}
  });
}