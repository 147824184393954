var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fap-quiz",class:[{'fap-quiz-black-bg': _vm.quizCompleted}]},[_c('div',{staticClass:"fap-quiz-inner-wrapper"},[_c('div',{staticClass:"fap-quiz-top-section"},[_c('div',{staticClass:"fap-quiz-top-section-start"},[(_vm.question === 1 || _vm.quizCompleted)?[_c('a',{attrs:{"href":_vm.urlCountryPrefix + 'our-products/product-selector',"title":"Return to Product Selectors"},on:{"onClick":function () {
                  if( _vm.wgtag ) {
                    if( _vm.question === 1) {
                      _vm.wgtag('event', 'click', { 
                        'event_category': _vm.gaEventCategory, 
                        'event_action': 'Start Over',
                        'event_label': 'From Question One'});
                    } else {
                      _vm.wgtag('event', 'click', { 
                        'event_category': _vm.gaEventCategory, 
                        'event_action': 'Completed',
                        'event_label': 'Start Over'});
                    }
                    
                  }
                }}},[_c('chevron'),_vm._v(" "),_c('span',[_vm._v("Return")])],1)]:[_c('span',{staticClass:"restart-quiz",on:{"click":function($event){return _vm.restartQuiz()}}},[_c('chevron'),_vm._v(" "),_c('span',[_vm._v("Start Over")])],1)]],2),_vm._v(" "),(!_vm.quizCompleted)?_c('div',{staticClass:"fap-quiz-top-section-center"},[_vm._v("\n            Step "),_c('b',[_vm._v(_vm._s(_vm.clockify(_vm.question)))]),_vm._v(" of "+_vm._s(_vm.clockify(Object.keys(_vm.questions[_vm.application]).length))),_c('br'),_vm._v(" "),_vm._l((_vm.questions[_vm.application]),function(question,index){return _c('span',{key:index,class:['dot',{ answered: question!=0 }]})})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fap-quiz-section-end"})]),_vm._v(" "),_c('div',{staticClass:"fap-quiz-main-section"},[_c('div',{staticClass:"fap-quiz-questions"},[(_vm.application === 'garden')?[(_vm.question === 1)?[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('ImageTextSelect',{attrs:{"text":'Tank water only',"selected":_vm.questions[_vm.application][1] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Garden Only', 
                                'event_action': 'What is your water source',
                                'event_label': 'Tank water only'});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"text":'Rainwater and mains switching',"selected":_vm.questions[_vm.application][1] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Garden Only', 
                                'event_action': 'What is your water source',
                                'event_label': 'Rainwater and mains switching'});
                            }
                          })}}})],1),_vm._v(" "),(_vm.questions[_vm.application][1] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(false, 'Pump Selector - Garden Only', 'What is your water source')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:(_vm.question === 2)?[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-quiz-previous-question",on:{"click":function($event){return _vm.previous()}}},[_c('arrow-previous'),_vm._v(" "),_c('span',[_vm._v("Previous")])],1),_vm._v(" "),_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('ImageTextSelect',{attrs:{"text":'0 - 2 Taps',"selected":_vm.questions[_vm.application][2] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,2, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Garden Only', 
                                'event_action': 'How many taps are running',
                                'event_label': '0-2 Taps'});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"text":'3+ Taps',"selected":_vm.questions[_vm.application][2] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,2, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Garden Only', 
                                'event_action': 'How many taps are running',
                                'event_label': '3+ Taps'});
                            }
                          })}}})],1),_vm._v(" "),(_vm.questions[_vm.application][2] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(true, 'Pump Selector - Garden Only', 'How many taps are running')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:_vm._e()]:_vm._e(),_vm._v(" "),(_vm.application === 'rain')?[(_vm.question === 1)?[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('ImageTextSelect',{attrs:{"type":'svg',"title":'Cottage',"selected":_vm.questions[_vm.application][1] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What size is your home',
                                'event_label': 'Cottage'});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"type":'svg',"title":'Small',"selected":_vm.questions[_vm.application][1] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What size is your home',
                                'event_label': 'Small'});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"type":'svg',"title":'Average',"selected":_vm.questions[_vm.application][1] === 3},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 3, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What size is your home',
                                'event_label': 'Average'});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"type":'svg',"title":'Large',"selected":_vm.questions[_vm.application][1] === 4},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,1, 4, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What size is your home',
                                'event_label': 'Large'});
                            }
                          })}}})],1),_vm._v(" "),(_vm.questions[_vm.application][1] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(false,'Pump Selector - Mains/Rainwater', 'What size is your home')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:(_vm.question === 2)?[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-quiz-previous-question",on:{"click":function($event){return _vm.previous()}}},[_c('arrow-previous'),_vm._v(" "),_c('span',[_vm._v("Previous")])],1),_vm._v(" "),_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('ImageTextSelect',{attrs:{"title":'1',"selected":_vm.questions[_vm.application][2] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,2, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'How many storeys does your home have?',
                                'event_label': 1});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"title":'2',"selected":_vm.questions[_vm.application][2] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,2, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'How many storeys does your home have?',
                                'event_label': 2});
                            }
                          })}}}),_vm._v(" "),_c('ImageTextSelect',{attrs:{"title":'3',"selected":_vm.questions[_vm.application][2] === 3},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,2, 3, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'How many storeys does your home have?',
                                'event_label': 3});
                            }
                          })}}})],1),_vm._v(" "),(_vm.questions[_vm.application][2] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(false, 'Pump Selector - Mains/Rainwater','How many storeys does your home have?')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:_vm._e(),_vm._v(" "),(_vm.question === 3)?[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-quiz-previous-question",on:{"click":function($event){return _vm.previous()}}},[_c('arrow-previous'),_vm._v(" "),_c('span',[_vm._v("Previous")])],1),_vm._v(" "),_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('div',{staticClass:"fap-quiz-answer-checkboxes"},[_c('CheckboxSelect',{attrs:{"text":'My tank is within 30m of home and less than 3m below home',"selected":_vm.questions[_vm.application][3] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,3, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What best describes current installation',
                                'event_label': 'Within 30m and less than 3m below'});
                            }
                          })}}}),_vm._v(" "),_c('CheckboxSelect',{attrs:{"text":'Other',"selected":_vm.questions[_vm.application][3] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,3, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What best describes current installation',
                                'event_label': 'Other'});
                            }
                          })}}})],1)]),_vm._v(" "),(_vm.questions[_vm.application][3] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(false, 'Pump Selector - Mains/Rainwater','What best describes current installation')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:(_vm.question === 4)?[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"fap-quiz-answer-section"},[_c('div',{staticClass:"fap-quiz-previous-question",on:{"click":function($event){return _vm.previous()}}},[_c('arrow-previous'),_vm._v(" "),_c('span',[_vm._v("Previous")])],1),_vm._v(" "),_c('div',{staticClass:"fap-duo-select fap-quiz-answers"},[_c('div',{staticClass:"fap-quiz-answer-checkboxes"},[_c('CheckboxSelect',{attrs:{"text":'In Tank Pump',"selected":_vm.questions[_vm.application][4] === 1},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,4, 1, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What float do you require',
                                'event_label': 'In tank pump'});
                            }
                          })}}}),_vm._v(" "),_c('CheckboxSelect',{attrs:{"text":'Surface Mount Pump - Top Entry Float',"selected":_vm.questions[_vm.application][4] === 2},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,4, 2, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What float do you require',
                                'event_label': 'Top Entry Float'});
                            }
                          })}}}),_vm._v(" "),_c('CheckboxSelect',{attrs:{"text":'Surface Mount Pump - Side Entry Float',"selected":_vm.questions[_vm.application][4] === 3},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,4, 3, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What float do you require',
                                'event_label': 'Side Entry Float'});
                            }
                          })}}}),_vm._v(" "),_c('CheckboxSelect',{attrs:{"text":'Surface Mount Pump - Floatless',"selected":_vm.questions[_vm.application][4] === 4},on:{"onClick":function($event){_vm.updateAnswer(_vm.application,4, 4, function () {
                            if( _vm.wgtag ) {
                              _vm.wgtag('event', 'click', { 
                                'event_category': 'Pump Selector - Mains/Rainwater', 
                                'event_action': 'What float do you require',
                                'event_label': 'Floatless'});
                            }
                          })}}})],1)]),_vm._v(" "),(_vm.questions[_vm.application][4] !== 0)?_c('div',{staticClass:"fap-quiz-next-question",on:{"click":function($event){return _vm.next(true, 'Pump Selector - Mains/Rainwater', 'What float do you require')}}},[_c('arrow-next'),_vm._v(" "),_c('span',[_vm._v("Next")])],1):_vm._e()])]:_vm._e()]:_vm._e(),_vm._v(" "),(_vm.quizCompleted && _vm.results && _vm.results.length > 0)?[_c('h1',[_vm._v("We found "+_vm._s(_vm.results.length)+" product(s) that might be right for you…")]),_vm._v(" "),_c('div',{staticClass:"m-product-landing-products-and-pagination fap-results"},[_c('ul',{staticClass:"fap-product-listing"},_vm._l((_vm.results.slice(_vm.displayStart, _vm.displayEnd)),function(listing){return _c('result-card',{key:listing.key,attrs:{"namehash":listing.namehash,"imageURI":listing.image,"imageAlt":listing.name,"title":listing.name,"description":listing.description,"datasheet":listing.datasheet,"sharelink":listing.sharelink,"regionPath":_vm.urlCountryPrefix,"dealerMode":_vm.dealerMode}})}),1),_vm._v(" "),_c('paginate',{attrs:{"next-class":'hide-next',"prev-class":'hide-prev',"active-class":'active-page',"page-count":_vm.pageCount,"click-handler":_vm.renderPage,"container-class":'m-pagination m-pagination-theme-white',"page-class":'m-product-landing-pagination-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]:(_vm.quizCompleted)?[_c('no-results',{attrs:{"url-country-prefix":_vm.urlCountryPrefix,"dealerMode":_vm.dealerMode}})]:_vm._e()],2)])]),_vm._v(" "),_c('div')])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("What is your water source?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("How many taps are you running in your garden?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("What size is your home?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("How many storeys does your home have?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("Lets talk about your pump installation.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fap-quiz-question"},[_c('h2',[_vm._v("What type of water detection device (float) do you require?")])])}]

export { render, staticRenderFns }