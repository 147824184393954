"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _paginateResults = _interopRequireDefault(require("./paginate-results.vue"));

var _utils = require("../utils");

var _productCard = _interopRequireDefault(require("./productLanding/product-card.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  extends: _paginateResults.default,
  components: {
    ProductCard: _productCard.default
  },
  props: {
    urlCountryPrefix: {
      type: String,
      default: ''
    },
    itemCount: {
      type: Number,
      default: 15
    },
    listings: Array,
    filters: Object,
    translations: Object
  },
  data: function () {
    return {
      hashValueType: '',
      hashValueApplication: ''
    };
  },

  mounted() {},

  computed: {
    productTypesSelectedAsString() {
      if (this.productTypesSelected) {
        const numberOfProductTypesSelected = this.productTypesSelected.length;

        if (numberOfProductTypesSelected === 1) {
          return this.productTypesSelected[0];
        }

        let multipleTypesFormatted = this.productTypesSelected[0];

        for (let i = 1; i < numberOfProductTypesSelected; i++) {
          multipleTypesFormatted = `${multipleTypesFormatted} & ${this.productTypesSelected[i]}`;
        }

        return multipleTypesFormatted;
      }

      return "All Products";
    },

    productTypesKeyedByID() {
      const keyed = {};
      this.filters.productTypes.forEach(filter => {
        keyed[filter.id] = filter.label;
      });
      return keyed;
    },

    productTypesSelected() {
      let productTypesSelected = false;
      this.filterFlags.forEach(flag => {
        if (flag && flag.name === "productTypes") productTypesSelected = flag;
      });

      if (productTypesSelected) {
        let reducedByName = [];
        productTypesSelected.values.forEach(flag => {
          reducedByName.push(this.productTypesKeyedByID[flag]);
        });
        return reducedByName;
      }

      return productTypesSelected;
    },

    shouldShowPagination() {
      return this.pageCount > 1;
    },

    shouldShowListing() {
      return this.numberOfResults > 0;
    },

    numberOfResults() {
      return this.finalListings.length;
    },

    pageCount() {
      return Math.round(this.numberOfResults / this.itemCount);
    },

    productTypeIsSelected() {
      return _.some(this.filterFlags, flag => flag.name === "productTypes");
    },

    finalListingsOrderedByPriority() {
      return _.orderBy(this.finalListings, ['priority', 'title'], ['desc', 'asc']);
    },

    finalListings() {
      const self = this;

      if (self.filterFlags.length > 0) {
        let i = self.listings.length;
        const listings = self.listings.slice().reverse();
        const filteredListing = [];

        while (i--) {
          let push = false;

          if (self.filterOperator === "AND") {
            self.filterFlags.every(function (flag, key) {
              push = false;

              if (listings[i][flag.name] !== undefined) {
                if (flag.name == 'productApplications') flag.values.every(function (val, key) {
                  push = false;
                  if (listings[i][flag.name].includes(val)) push = true;
                  return push;
                });else listings[i][flag.name].forEach(function (val, key) {
                  if (flag.values.includes(val)) push = true;
                });
              }

              return push;
            });
          } else {
            self.filterFlags.forEach(function (flag, key) {
              if (listings[i][flag.name] !== undefined) {
                listings[i][flag.name].forEach(function (val, key) {
                  if (flag.values.includes(val)) push = true;
                });
              }
            });
          }

          if (push) {
            filteredListing.push(listings[i]);
          }
        }

        return filteredListing;
      } else {
        return self.listings;
      }

      return [];
    }

  },
  methods: {
    shouldShowItem(index, option) {
      if (index === 'productApplications') {
        return this.listingCounts(index, option.id) !== 0;
      }

      return true;
    },

    updateBreadcrumbs() {
      const breadcrumbContainer = document.getElementById('product-landing-breadcrumbs-content');
      breadcrumbContainer.innerHTML = this.productTypesSelectedAsString;
    },

    listingCounts: function (filter, value) {
      let count = 0;
      this.finalListings.forEach(function (listing, key) {
        listing[filter].forEach(function (val) {
          if (val == value) count++;
        });
      });
      return count;
    },
    filterListings: function (filter, value, event) {
      var self = this;

      if (event && !event.target.checked) {
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) {
            if (flag.values.includes(value)) flag.values.splice(flag.values.indexOf(value), 1);
            if (flag.values.length == 0) self.filterFlags.splice(key, 1);
          }
        });
      } else {
        var push = true;
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) {
            if (!flag.values.includes(value)) flag.values.push(value);
            push = false;
          }
        });
        if (push) self.filterFlags.push({
          'name': filter,
          'values': [value]
        });
      }

      this.page = 1;
      this.updateBreadcrumbs();
    },
    filterByHash: function () {
      var self = this;
      const applications = (0, _utils.getHashValue)('application') !== null ? (0, _utils.getHashValue)('application').split(',') : [];
      const types = (0, _utils.getHashValue)('type') !== null ? (0, _utils.getHashValue)('type').split(',') : [];
      applications.forEach(function (application) {
        var push = true;
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == 'productApplications') {
            if (!flag.values.includes(application)) flag.values.push(application);
            push = false;
          }
        });
        if (push) self.filterFlags.push({
          'name': 'productApplications',
          'values': [application]
        });
      });
      types.forEach(function (type) {
        var push = true;
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == 'productTypes') {
            if (!flag.values.includes(type)) flag.values.push(type);
            push = false;
          }
        });
        if (push) self.filterFlags.push({
          'name': 'productTypes',
          'values': [type]
        });
      });
    }
  }
};
exports.default = _default;