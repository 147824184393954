"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ProductSlider {
  constructor() {
    if (document.querySelector('.m-product-detail') === null) return null;
    this.windowSlider = (0, _jquery.default)('.m-product-detail-image-window');
    this.desktopSlider = (0, _jquery.default)('.m-product-detail-nav');
    this.mobileSlider = (0, _jquery.default)('.m-product-detail-nav-mobile');
    this.initSlider();
    (0, _jquery.default)(window).on('resize', (0, _lodash.debounce)(() => this.initSlider(), 150));
  }

  initSlider() {
    this.unslick();

    if (this.isMobile()) {
      this.initMobileSlider();
    } else {
      this.initDesktopSlider();
    }
  }

  unslick() {
    (0, _jquery.default)('.slick-slider').slick('unslick');
  }

  initDesktopSlider() {
    // this.windowSlider = $('.m-product-detail-image-window')
    // this.desktopSlider = $('.m-product-detail-nav')
    this.windowSlider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.m-product-detail-nav'
    });
    this.desktopSlider.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.m-product-detail-image-window',
      dots: false,
      focusOnSelect: true,
      variableWidth: true,
      arrows: true,
      prevArrow: '.m-product-detail-nav-wrapper .m-product-detail-nav-arrow-left',
      nextArrow: '.m-product-detail-nav-wrapper .m-product-detail-nav-arrow-right'
    });
  }

  initMobileSlider() {
    this.windowSlider.slick({
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.m-product-detail-nav-mobile'
    });
    this.mobileSlider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.m-product-detail-image-window',
      centerMode: true,
      dots: false,
      focusOnSelect: true,
      variableWidth: true,
      prevArrow: (0, _jquery.default)('.m-product-detail-nav-mobile-arrow-left'),
      nextArrow: (0, _jquery.default)('.m-product-detail-nav-mobile-arrow-right')
    });
  }

  isMobile() {
    return (0, _jquery.default)(window).width() < 900;
  }

}

var _default = ProductSlider;
exports.default = _default;