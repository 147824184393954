"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

class Modal {
  constructor(target) {
    const btn = document.getElementById(target);
    const modal = document.querySelector('.modal');

    function attachModalListeners(modalElm) {
      modalElm.querySelector('.close-modal').addEventListener('click', toggleModal);
      modalElm.querySelector('.overlay').addEventListener('click', toggleModal);
    }

    function detachModalListeners(modalElm) {
      modalElm.querySelector('.close-modal').removeEventListener('click', toggleModal);
      modalElm.querySelector('.overlay').removeEventListener('click', toggleModal);
    }

    function toggleModal() {
      const currentState = modal.style.display;

      if (currentState === 'none') {
        modal.style.display = 'block';
        attachModalListeners(modal);
      } else {
        modal.style.display = 'none';
        detachModalListeners(modal);
      }
    }

    btn.addEventListener('click', toggleModal);
  }

}

exports.default = Modal;