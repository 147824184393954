"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapStyles = exports.getSignedURL = exports.getQueryValue = exports.getHashValue = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _config = _interopRequireDefault(require("./config"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getHashValue = key => location.hash.match(new RegExp(key + "=([^&]*)")) ? location.hash.match(new RegExp(key + "=([^&]*)"))[1] : null;

exports.getHashValue = getHashValue;

const getQueryValue = key => location.href.match(new RegExp(key + "=([^&]*)")) ? location.href.match(new RegExp(key + "=([^&]*)"))[1] : null;

exports.getQueryValue = getQueryValue;

const getSignedURL = file => {
  let endpoint = _config.default.SIGNING_ENDPOINT;
  let payload = {
    filePath: file.name,
    contentType: file.type
  };
  return _axios.default.post(endpoint, payload).then(res => {
    // console.log("response", {endpoint, payload, res})
    // console.log("response endpoint?", res.data.postEndpoint)
    return Promise.resolve(res.data.postEndpoint || '/');
  }).catch(err => {
    console.error(err);
    return Promise.reject('/');
  });
};

exports.getSignedURL = getSignedURL;
const mapStyles = [{
  "featureType": "all",
  "elementType": "labels.text.fill",
  "stylers": [{
    "saturation": 36
  }, {
    "color": "#000000"
  }, {
    "lightness": 40
  }]
}, {
  "featureType": "all",
  "elementType": "labels.text.stroke",
  "stylers": [{
    "visibility": "on"
  }, {
    "color": "#000000"
  }, {
    "lightness": 16
  }]
}, {
  "featureType": "all",
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "administrative",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 20
  }]
}, {
  "featureType": "administrative",
  "elementType": "geometry.stroke",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 17
  }, {
    "weight": 1.2
  }]
}, {
  "featureType": "landscape",
  "elementType": "geometry",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 20
  }]
}, {
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 21
  }]
}, {
  "featureType": "road.highway",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 17
  }]
}, {
  "featureType": "road.highway",
  "elementType": "geometry.stroke",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 29
  }, {
    "weight": 0.2
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "geometry",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 18
  }]
}, {
  "featureType": "road.local",
  "elementType": "geometry",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 16
  }]
}, {
  "featureType": "transit",
  "elementType": "geometry",
  "stylers": [{
    "color": "#000000"
  }, {
    "lightness": 19
  }]
}, {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [{
    "color": "#2e2c2c"
  }, {
    "lightness": 17
  }]
}];
exports.mapStyles = mapStyles;