"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _findAProduct = _interopRequireDefault(require("./findAProduct.vue"));

var _block = _interopRequireDefault(require("./findAProduct/block.vue"));

var _imageTextSelect = _interopRequireDefault(require("./findAProduct/imageTextSelect.vue"));

var _numberSlider = _interopRequireDefault(require("./findAProduct/numberSlider.vue"));

var _noResults = _interopRequireDefault(require("./findAProduct/no-results.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'find-a-product-pressure-systems',
  extends: _findAProduct.default,
  components: {
    NumberSlider: _numberSlider.default,
    NoResults: _noResults.default
  },
  props: {
    urlCountryPrefix: {
      type: String,
      default: '/au/'
    },
    pipeFrictionMatrix: Array
  },
  data: () => ({
    questions: {
      pressure: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        4.1: 0,
        4.2: 0,
        4.3: 0,
        4.4: 0,
        4.5: 0,
        4.6: 0,
        4.7: 0,
        5: 0
      }
    }
  }),
  computed: {
    pipeFrictionPumpToTank: function () {
      let self = this;
      let pf = self.pipeFrictionMatrix.filter(fmatrix => {
        if (fmatrix[1] == self.currentAnswers[1] && fmatrix[4] == self.currentAnswers[4.6] && fmatrix[5] == self.currentAnswers[4.7]) return true;
      });
      if (pf.length > 0) pf = pf[0].frictionValue;else pf = 0;
      return pf;
    },
    pipeFrictionPumpToHouse: function () {
      let self = this;
      let pf = self.pipeFrictionMatrix.filter(fmatrix => {
        if (fmatrix[1] == self.currentAnswers[1] && fmatrix[4] == self.currentAnswers[4.2] && fmatrix[5] == self.currentAnswers[4.3]) return true;
      });
      if (pf.length > 0) pf = pf[0].frictionValue;else pf = 0;
      return pf;
    },
    steps: function () {
      let steps = new Array();
      Object.keys(this.questions[this.application]).forEach(index => {
        if (index % 1 === 0) steps[index] = {
          'question': index,
          'answer': this.questions[this.application][index]
        };
      });
      return steps.filter(function (val) {
        return val != undefined;
      });
    },
    questionNavDisplay: function () {
      // We skip a number twice because some of the template pages answer two questions at once.
      if (this.question === 4) return '4 - Section 1 of 6';else if (this.question === 4.2) return '4 - Section 2 of 6';else if (this.question === 4.3) return '4 - Section 3 of 6';else if (this.question === 4.4) return '4 - Section 4 of 6';else if (this.question === 4.6) return '4 - Section 5 of 6';else if (this.question === 4.7) return '4 - Section 6 of 6';else return '<strong>' + this.clockify(this.question) + '</strong> of ' + this.clockify(this.steps.length);
    },

    distancePumpToHouse() {
      return this.currentAnswers[4];
    },

    verticalHeightPumpToHouse() {
      return this.currentAnswers[4.1];
    },

    distanceTankToPump() {
      return this.currentAnswers[4.4];
    },

    verticalHeightTankToPump() {
      return this.currentAnswers[4.5];
    },

    thlevel() {
      if (this.questions[this.application][3] === 2) {
        // (distance pump to house x (Pipe friction value/100) + Vertical height pump to house) + (distance tank to pump x (Pipe friction value/100) + Vertical height tank to pump)
        const distancePumpToHouse = this.distancePumpToHouse;
        const verticalHeightPumpToHouse = this.verticalHeightPumpToHouse * -1; // Numbers have to be inverted as per clients request

        const distanceTankToPump = this.distanceTankToPump;
        const verticalHeightTankToPump = this.verticalHeightTankToPump * -1; // Numbers have to be inverted as per clients request

        const pipeFrictionPumpToHouse = this.pipeFrictionPumpToHouse;
        const pipeFrictionPumpToTank = this.pipeFrictionPumpToTank; // Left Hand Side

        const pipeFrictionToHouseDividedByHundred = pipeFrictionPumpToHouse / 100;
        const distancePumpToHouseByFriction = distancePumpToHouse * pipeFrictionToHouseDividedByHundred;
        const distanceFrictionVerticalHeightPumpToHouse = distancePumpToHouseByFriction + verticalHeightPumpToHouse; // Right Hand Side

        const pipeFrictionToTankDividedByHundred = pipeFrictionPumpToTank / 100;
        const distancePumpToTankByFriction = distanceTankToPump * pipeFrictionToTankDividedByHundred;
        const distanceFrictionVerticalHeightPumpToTank = distancePumpToTankByFriction + verticalHeightTankToPump;
        const th = distanceFrictionVerticalHeightPumpToHouse + distanceFrictionVerticalHeightPumpToTank;
        return th;
      }

      return null;
    },

    result: function () {
      let self = this;
      return self.currentMatrix.filter(matrix => {
        if (matrix.answers[1] == self.currentAnswers[1] && matrix.answers[2] == self.currentAnswers[2] && matrix.answers[3] == self.currentAnswers[3] && matrix.answers[5] == self.currentAnswers[5]) {
          if (isNaN(matrix.answers.maxTh) && isNaN(matrix.answers.minTh)) return true; // (distance pump to house x (Pipe friction value/100) + Vertical height pump to house) + (distance tank to pump x (Pipe friction value/100) + Vertical height tank to pump)          

          const th = self.thlevel; // console.log('Calculated TH: ' + th);

          let bool = true;
          if (!isNaN(matrix.answers.maxTh) && th > matrix.answers.maxTh) bool = false;
          if (!isNaN(matrix.answers.minTh) && th < matrix.answers.minTh) bool = false;
          return bool;
        }
      });
    },
    results: function () {
      return this.result && this.result[0] && this.result[0].result;
    }
  },
  methods: {
    restartQuiz() {
      if (this.wgtag) {
        this.wgtag('event', 'click', {
          'event_category': "Pump Selector - Home Pressure",
          'event_action': "RestartQuiz",
          'event_label': `Question ${this.question}`
        });
      }

      this.question = 1;
    },

    checkAnswered: function (question) {
      if (question == 4) {
        if (this.questions[this.application][3] == 3 || this.questions[this.application][3] == 3) {
          return true;
        } else if (this.questions[this.application][3] == 2 && this.questions[this.application][4] != 0 && this.questions[this.application][4.1] != 0 && this.questions[this.application][4.2] != 0 && this.questions[this.application][4.3] != 0 && this.questions[this.application][4.4] != 0 && this.questions[this.application][4.5] != 0 && this.questions[this.application][4.6] != 0 && this.questions[this.application][4.7] != 0) {
          return true;
        } else {
          return false;
        }
      } else if (question == 5 && this.questions[this.application][3] == 3) {
        return true;
      } else {
        return this.questions[this.application][question] != 0;
      }
    },
    updateDistanceAnswerpth: function (answer) {
      this.questions[this.application][4] = answer;
    },
    updateHeightAnswerpth: function (answer) {
      this.questions[this.application][4.1] = answer;
    },
    updateDistanceAnswerptt: function (answer) {
      this.questions[this.application][4.4] = answer;
    },
    updateHeightAnswerptt: function (answer) {
      this.questions[this.application][4.5] = answer;
    },
    next: function (quizCompleted = false, cat, action, callback) {
      if (this.question == 3 && this.questions[this.application][this.question] == 1) {
        this.question = 5;
      } else if (this.question == 3 && this.questions[this.application][this.question] == 3) {
        this.question = 6;
        quizCompleted = true;
      } else if (this.question == 4) {
        this.question = 4.2;
      } else if (this.question == 4.2) {
        this.question = 4.3;
      } else if (this.question == 4.3) {
        this.question = 4.4;
      } else if (this.question == 4.4) {
        this.question = 4.6;
      } else if (this.question == 4.6) {
        this.question = 4.7;
      } else if (this.question == 4.7) {
        this.question = 5;
      } else {
        this.question = this.question + 1;
      }

      if (quizCompleted) {
        this.quizCompleted = true;
      }

      if (this.wgtag) {
        if (quizCompleted) {
          this.wgtag('event', 'click', {
            'event_category': cat,
            'event_action': action,
            'event_label': 'Submit'
          });
          this.wgtag('event', 'click', {
            'event_category': "Pump Selector - Home Pressure",
            'event_action': "Completed",
            'event_label': 'Completed'
          });
        } else {
          this.wgtag('event', 'click', {
            'event_category': cat,
            'event_action': action,
            'event_label': 'Next'
          });
        }
      }

      if (callback) {
        callback();
      }
    },
    previous: function () {
      if (this.question == 5 && this.questions[this.application][3] == 1) this.question = 3;else if (this.question == 5) this.question = 4.7;else if (this.question == 4.7) this.question = 4.6;else if (this.question == 4.6) this.question = 4.4;else if (this.question == 4.4) this.question = 4.3;else if (this.question == 4.3) this.question = 4.2;else if (this.question == 4.2) this.question = 4;else this.question = this.question - 1;
    }
  }
};
exports.default = _default;