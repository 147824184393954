"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _arrowRight = _interopRequireDefault(require("../common/arrow-right.vue"));

var _phone = _interopRequireDefault(require("../common/phone.vue"));

var _email = _interopRequireDefault(require("../common/email.vue"));

var _pin = _interopRequireDefault(require("../common/pin.vue"));

var _cog = _interopRequireDefault(require("./cog.vue"));

var _premiumDealer = _interopRequireDefault(require("./premium-dealer.vue"));

var _distributor = _interopRequireDefault(require("./distributor.vue"));

var _standardDealer = _interopRequireDefault(require("./standard-dealer.vue"));

var _dealerSpotlight = _interopRequireDefault(require("./dealer-spotlight.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowRight: _arrowRight.default,
    Phone: _phone.default,
    Email: _email.default,
    Pin: _pin.default,
    StandardDealer: _standardDealer.default,
    PremiumDealer: _premiumDealer.default,
    Distributor: _distributor.default,
    DealerSpotlight: _dealerSpotlight.default,
    Cog: _cog.default
  },
  props: {
    href: String,
    title: String,
    target: String,
    showTypeLogo: Boolean,
    isServiceDealer: Boolean,
    isFeatured: String,
    phone: String,
    email: String,
    emailDealerText: String,
    address: String,
    suburb: String,
    state: String,
    suburb: String,
    postcode: String,
    country: String,
    distance: String,
    servicesTitle: String,
    type: String,
    services: String,
    dealerWebsite: String,
    visitWebsiteText: String,
    countryPrefix: String
  },
  computed: {
    featuredHref() {
      return this.isFeatured ? this.isFeatured : this.href;
    },

    showArrowLink() {
      return this.dealerWebsite !== null && this.dealerWebsite.length > 0;
    }

  },
  methods: {
    phoneClicked() {
      if (window.gtag) {
        window.gtag('event', 'click', {
          'event_category': 'Dealer Locator',
          'event_action': 'Phone Number Click',
          'event_label': this.title
        });
      }
    },

    emailClicked() {
      if (window.gtag) {
        window.gtag('event', 'click', {
          'event_category': 'Dealer Locator',
          'event_action': 'Email Dealer Click',
          'event_label': this.title
        });
      }
    },

    websiteClicked() {
      if (window.gtag) {
        window.gtag('event', 'click', {
          'event_category': 'Dealer Locator',
          'event_action': 'Dealer Website Click',
          'event_label': this.title
        });
      }
    }

  }
};
exports.default = _default;