"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'product-warranty',
  components: {
    vSelect: _vueSelect.default
  },
  props: {
    listings: Array,
    urlCountryPrefix: {
      type: String,
      default: '/au/'
    },
    filters: Object,
    translations: Object
  },
  data: () => ({
    filteredListings: [],
    filterFlags: []
  }),

  created() {
    this.filteredListings = this.listings;
    var self = this;
    Object.entries(self.listingFilters).forEach(function (filter, key) {
      filter[1].forEach(function (option, index) {
        if (option.checked) self.filterListings(filter[0], option.id, false);
      });
    });
  },

  computed: {
    resultCount: function () {
      return this.filteredListings.length;
    },
    isFiltering: function () {
      return this.filterFlags.length > 0;
    },
    listingFilters: function () {
      var filters = Object.assign({}, this.filters);
      delete filters.filter_operator;
      return filters;
    }
  },
  beforeMount: function () {},
  methods: {
    filterListings: function (filter, value) {
      var self = this;

      if (value == -1) {
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) self.filterFlags.splice(key, 1);
        });
      } else {
        var push = true;
        self.filterFlags.forEach(function (flag, key) {
          if (flag.name == filter) {
            self.filterFlags[key].value = value;
            push = false;
          }
        });
        if (push) self.filterFlags.push({
          'name': filter,
          'value': value
        });
      }

      var i = self.listings.length;
      self.filteredListings = [];
      var listings = self.listings.slice().reverse();
      if (self.filterFlags.length > 0) while (i--) {
        var push = false;

        if (this.filterOperator == "AND") {
          //console.log('record',listings[i].key,'\n');
          self.filterFlags.every(function (flag, key) {
            push = false; //console.log('filter',flag.name,'value',flag.value,'\n');

            if (listings[i][flag.name] != undefined) {
              if (Array.isArray(listings[i][flag.name])) listings[i][flag.name].forEach(function (val, key) {
                if (val == flag.value) {
                  push = true;
                }
              });
            } //console.log('push',push,'\n');


            if (!push) return false;else return true;
          });
        } else {
          self.filterFlags.forEach(function (flag, key) {
            if (listings[i][flag.name] != undefined) {
              if (Array.isArray(listings[i][flag.name])) listings[i][flag.name].forEach(function (val, key) {
                if (val == flag.value) push = true;
              });
            }
          });
        }

        if (push) self.filteredListings.push(listings[i]);
      } else self.filteredListings = self.listings;
      this.page = 1;
    }
  }
};
exports.default = _default;