"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ResponsiveSliderFactory {
  constructor(classNames) {
    if (classNames.length < 1) return null;
    this.classNames = classNames;
    this.sliders = [];
    classNames.forEach(className => {
      this.sliders.push((0, _jquery.default)(className));
    });
    this.initSliders();
    (0, _jquery.default)(window).on('resize', (0, _lodash.debounce)(() => this.initSliders(), 150));
  }

  initSliders() {
    // this.resetHeights()
    this.sliders.forEach(slider => {
      this.initSlider(slider);
    }); // if(!this.isMobile()) {
    //   this.matchHeights()
    // }
  }

  initSlider(element) {
    this.removeSlider(element);
    if (!this.isMobile() && !this.sliderInit(element)) return element;
    element.slick({
      adaptiveHeight: true,
      arrows: false,
      centerMode: true,
      centerPadding: '50px',
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 500,
      cssEase: 'linear'
    });
    return element;
  }

  removeSlider(element) {
    return this.sliderInit(element) && element.slick('unslick');
  }

  sliderInit(element) {
    return element[0].classList && element[0].classList.contains('slick-initialized');
  }

  matchHeights() {
    this.sliders.forEach(slider => {
      const $elementsToMatch = slider.find('.match-heights');
      let tallest = 0;
      $elementsToMatch.each(index => {
        const height = (0, _jquery.default)($elementsToMatch[index]).height();
        if (height > tallest) tallest = height;
      });
      $elementsToMatch.each(index => {
        (0, _jquery.default)($elementsToMatch[index]).height(tallest);
      });
    });
  }

  resetHeights() {
    this.sliders.forEach(slider => {
      (0, _jquery.default)(slider).find('.match-heights').height('initial');
    });
  }

  isMobile() {
    return window.innerWidth < 600;
  }

}

var _default = ResponsiveSliderFactory;
exports.default = _default;