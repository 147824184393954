"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class FormHandler {
  constructor() {
    if ((0, _jquery.default)('.m-form').length === 0) return null;
    this.inputs = (0, _jquery.default)(".m-form").find("input[type='text']"); // 1. loop over the inputs.

    (0, _lodash.forEach)(this.inputs, input => {
      // 2. Add an on change handler
      (0, _jquery.default)(input).on('change', e => {
        const $label = (0, _jquery.default)(input).siblings('label'); // 3. On change, if value !== nothing, force up.

        if (e.target.value !== "") {
          $label.addClass('forceup');
        } else {
          $label.removeClass('forceup');
        }
      });
    });
  }

}

var _default = FormHandler;
exports.default = _default;