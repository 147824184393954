"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class MatchHeights {
  constructor() {
    this.parents = document.querySelectorAll('.match-heights-isolated-group');
    this.matchHeightsParent = [];
    this.parents.forEach(parent => {
      const children = parent.querySelectorAll('.match-heights-isolated');
      this.matchHeightsParent.push({
        target: parent,
        children
      });
    });
    if (this.matchHeightsParent.length === 0) return null;
    this.matchHeights();
    (0, _jquery.default)(window).on('resize', (0, _lodash.debounce)(() => this.handleResize(), 150));
  }

  handleResize() {
    this.resetHeights();
    this.matchHeights();
  }

  matchHeights() {
    this.matchHeightsParent.forEach(parent => {
      let tallest = 0;
      parent.children.forEach(target => {
        const height = (0, _jquery.default)(target).height();
        if (height > tallest) tallest = height;
      });
      parent.children.forEach(target => {
        (0, _jquery.default)(target).height(tallest);
      });
    });
  }

  resetHeights() {
    this.matchHeightsParent.forEach(parent => {
      parent.children.forEach(target => {
        (0, _jquery.default)(target).height('initial');
      });
    });
  }

}

var _default = MatchHeights;
exports.default = _default;