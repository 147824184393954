"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _arrowRight = _interopRequireDefault(require("./common/arrow-right.vue"));

var _loading = _interopRequireDefault(require("./common/loading.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowRight: _arrowRight.default,
    Loading: _loading.default
  },

  data() {
    return {
      loading: false,
      selected: '',
      inputProps: {
        id: "autosuggest__input",
        placeholder: "Enter Search Keyword"
      },
      searchPhrase: '',
      filteredOptions: [],
      limit: 10,
      axios: _axios.default.create({
        baseURL: window.location.origin + "/api/"
      })
    };
  },

  props: {
    regionKey: {
      type: String,
      default: 'au'
    }
  },
  name: 'navSearch',
  methods: {
    fetchResults: _lodash.default.debounce(function () {
      // console.log("Performing search with query: ", this.searchPhrase);
      this.loading = true;
      const URI = `search?q=${this.searchPhrase}&regionKey=${this.regionKey}`;
      var self = this;
      this.axios.get(URI).then(function (response) {
        self.filteredOptions = [{
          data: response.data.data
        }];
        self.loading = false;
      });
    }, 250),

    getSuggestionValue(option) {
      window.open(`/${this.regionKey}/${option.item.key}`, "_self");
      return option.item.title;
    },

    onSelected(option) {
      if (option && option.item) {
        this.selected = option.item.title;
      } else {
        this.redirectToSearchPage();
      }
    },

    onInputChange(text) {
      if (text === '' || text === undefined) {
        return;
      }

      this.searchPhrase = text;
      this.fetchResults();
    },

    formatSearchResult(text) {
      if (!text) return undefined;
      const regExed = text.replace(new RegExp(this.searchPhrase, "gi"), '<strong>$&</strong>');
      return regExed;
    },

    redirectToSearchPage() {
      window.location.href = this.searchURIWithQuery;
    }

  },
  computed: {
    hasNoResults() {
      return this.filteredOptions && this.filteredOptions.length === 1 && this.filteredOptions[0].data === undefined;
    },

    queryIsValid() {
      return this.searchPhrase !== null && this.searchPhrase !== undefined && this.searchPhrase !== '' && this.searchPhrase.length > 0;
    },

    queryToShort() {
      return this.searchPhrase.length <= 3;
    },

    searchURIWithQuery() {
      return `/${this.regionKey}/search?query=${this.searchPhrase}`;
    }

  }
};
exports.default = _default;