"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _axios = _interopRequireDefault(require("axios"));

var _arrowLinkButton = _interopRequireDefault(require("./common/arrow-link-button.vue"));

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowLink: _arrowLinkButton.default,
    vSelect: _vueSelect.default
  },

  data() {
    return {
      region: null,
      actionUriDismiss: "/",
      loading: true,
      ip: "",
      location: "",
      inCorrectRegion: true,
      regionOptions: [],
      axios: _axios.default.create({
        baseURL: window.location.origin + "/api/"
      })
    };
  },

  props: {
    regionSelectMessage: {
      type: String,
      default: "Choose another region to see content specific to your location."
    },
    baseActionUri: {
      type: String,
      default: "/au"
    },
    translations: Object
  },
  mounted: async function () {
    this.region = this.regionOptions[0];
    this.actionUriDismiss = window.location.pathname;
    await this.fetchIP();
    await this.determineLocation();
  },
  computed: {
    displayNotice() {
      // console.log({"Cookies.get('region-interaction')": Cookies.get('region-interaction'), inCorrectRegion: this.inCorrectRegion})
      if (_jsCookie.default.get('region-interaction')) return false;
      return !this.inCorrectRegion; // return this.shouldDisplayNotice && !isEmpty(this.regionOptions)

      return true;
    },

    regionIsValid() {
      return this.region && (0, _lodash.has)(this.region, "uri");
    },

    encodedRegion() {
      if (this.regionIsValid) {
        return this.region.encoded;
      }

      return '';
    },

    actionUri() {
      if (this.regionIsValid) {
        return this.region.uri;
      }

      return this.baseActionUri;
    }

  },
  methods: {
    onSubmit: function () {
      _jsCookie.default.set('region-interaction', true, {
        expires: 7
      });

      return true;
    },
    fetchIP: async function () {
      this.loading = true;
      let response = await _axios.default.get("https://api.ipify.org?format=json");

      if (response.status === 200) {
        this.ip = response.data.ip;
      }

      this.loading = false;
    },
    determineLocation: async function () {
      this.loading = true;
      const ip = this.ip;
      const currentRegion = window && window.location && window.location.pathname && window.location.pathname.split("/")[1] ? window.location.pathname.split("/")[1] : "au";
      const URI = `determine-region?ip=${ip}&current=${currentRegion}&request_uri=${window.location.pathname}`;

      var _vm = this;

      _vm.axios.get(URI).then(function (response) {
        // console.log("The Response: ", response)
        if (response.status === 200) {
          _vm.location = response.data.location;
          _vm.regionOptions = response.data.region_options;
          _vm.inCorrectRegion = response.data.in_correct_region;
        }

        _vm.loading = false;
      });
    }
  }
};
exports.default = _default;