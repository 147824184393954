"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// {forceup: form[field.name].length > 0}
var _default = {
  props: {
    name: String,
    type: String,
    model: String,
    errorMessage: String,
    label: String,
    hasError: Boolean
  },

  data() {
    return {
      inputHasContent: false
    };
  },

  computed: {
    inputLength() {
      return 1;
    }

  },
  methods: {
    handleChange(event) {
      this.inputHasContent = event.target.textLength > 0;
    }

  }
};
exports.default = _default;