"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _arrowLink = _interopRequireDefault(require("../common/arrow-link.vue"));

var _arrowDown = _interopRequireDefault(require("../common/arrow-down.vue"));

var _arrowLinkButton = _interopRequireDefault(require("../common/arrow-link-button.vue"));

var _email = _interopRequireDefault(require("../common/email.vue"));

var _vueRecaptchaV = require("vue-recaptcha-v3");

var _axios = _interopRequireDefault(require("axios"));

var _modal = _interopRequireDefault(require("../../../modal.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowLink: _arrowLink.default,
    ArrowDown: _arrowDown.default,
    ArrowLinkButton: _arrowLinkButton.default,
    'vue-recaptcha': _vueRecaptchaV.VueReCaptcha,
    Modal: _modal.default,
    Email: _email.default
  },
  props: {
    regionPath: {
      type: String,
      default: '/au/'
    },
    namehash: String,
    imageURI: String,
    imageAlt: String,
    title: String,
    datasheet: String,
    sharelink: String,
    description: String,
    dealerMode: Boolean
  },
  data: () => ({
    summary: true,
    sendingSuccessful: false,
    sendingFailed: false
  }),
  methods: {
    trunc(str, len = 80) {
      (0, _lodash.trim)(str);
      return (0, _lodash.truncate)(str, {
        'length': len
      });
    },

    onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.sendingSuccessful = false;
      self.sendingFailed = false;
      const formData = new FormData();
      formData.append('share-to-email', this.$refs.shareEmail.value);
      formData.append('g-recaptcha-response', recaptchaToken);

      _axios.default.post(this._props.sharelink, formData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }).then(function (response) {
        self.sucessfulServerResponse = response.data.message;
        self.sendingSuccessful = true;
      }.bind(this)).catch(function (err) {
        self.sendingFailed = true;
        let responseBody;
        responseBody = err.response;

        if (!responseBody) {
          responseBody = err;
        } else {
          responseBody = err.response.data || responseBody;
        }

        self.serverError = responseBody.message || JSON.stringify(responseBody);
      }.bind(this)).then(() => {
        self.status = "";
      });
    },

    submitShareLink: function (event) {
      event.preventDefault();
      this.$recaptcha('login').then(this.onCaptchaVerified).catch(error => {
        alert('Failed Recaptcha Validation. Please close the window and try again'); // console.log(error)
      });
    }
  }
};
exports.default = _default;