"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _arrowLinkButton = _interopRequireDefault(require("./common/arrow-link-button.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowLink: _arrowLinkButton.default
  },

  data() {
    return {
      // Hide/Show cookie box 
      shouldDisplayNotice: false
    };
  },

  props: {
    // Cookie Notice Text
    notice: {
      type: String,
      default: null
    },
    // Cookie Notice Document Link
    cookieNoticeDocument: {
      tope: String,
      default: null
    },
    // Google Measurement ID
    googleMeasurementId: {
      type: String,
      default: 'UA-147644084-2'
    },
    // Cookie identifier key
    cookieKey: {
      type: String,
      default: 'is_allowed_cookie'
    }
  },
  created: function () {
    // Clear all cookie when cookie notice is not completely created
    // for current region zone
    if (this.notice === null || !this.notice) {
      this.clearCookie();
      return;
    } // Perform cookie yumyum!
    // when cookie is cooked in pimcore's region zone


    this.startCookie();
  },
  methods: {
    /**
     * Perform cookie consent alert
     */
    startCookie() {
      // If cookie notice is set for region and 
      // user already accepted or declined to use cookie
      // should not display cookie notice again until cookie is expire
      let cookie = this.getCookie();
      let userShouldConfirmToUseCookie = cookie === "true" || cookie === "false";

      if (userShouldConfirmToUseCookie) {
        // Hide cookie notice box
        return this.displayNotice(false);
      } // Display cookie notice box


      return this.displayNotice(true);
    },

    /**
     * To enable tracking cookie and close cookie notice
     */
    acceptCookie() {
      this.useCookie(true);
      this.displayNotice(false);
    },

    /**
     * To disable tracking cookie and close cookie notice
     */
    declineCookie() {
      this.useCookie(false);
      this.displayNotice(false);
    },

    /**
     * Display cookie notice alert box
     */
    displayNotice(shouldDisplay) {
      this.$data.shouldDisplayNotice = shouldDisplay;
    },

    /**
     * Set cookie value
     */
    useCookie(useCookie) {
      try {
        this.$cookies.set(this.cookieKey, useCookie);
        window[`ga-disable-${this.googleMeasurementId}`] = !useCookie;
      } catch (error) {
        throw new Error("Error: trying to set the cookie", error);
      }
    },

    /**
     * Get cookie value
     */
    getCookie() {
      return this.$cookies.get(this.cookieKey);
    },

    /**
     * Clear all cookie key === cookieKey
     */
    clearCookie() {
      try {
        let self = this;
        let cookies = this.$cookies.keys().filter(key => key.includes(this.cookieKey));
        cookies.forEach(cookieKey => {
          self.$cookies.remove(cookieKey);
        });
      } catch (error) {
        throw new Error("Error trying to clear all cookies", error);
      }
    }

  }
};
exports.default = _default;