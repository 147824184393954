"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PushImagesIntoBackground {
  constructor() {
    if (document.querySelector('.b-text-with-image') === null) return null;
    window.images = document.querySelectorAll('.javascript-push-into-bg');
    document.querySelectorAll('.javascript-push-into-bg').forEach(imageParent => {
      const image = imageParent.querySelector('img');
      const src = image.src;
      imageParent.style.backgroundImage = `url('${src}')`;
      imageParent.style.backgroundSize = 'cover';
      image.style.display = 'none';
    });
  }

}

var _default = PushImagesIntoBackground;
exports.default = _default;