"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class MainNavigation {
  constructor() {
    if (document.querySelector('.m-nav-search-toggle') === null) return null;
    this.toggleButton = document.querySelector('.m-nav-search-toggle');
    this.searchButton = document.querySelector('.m-nav-search-icon');
    this.closeButton = document.querySelector('.m-nav-close-icon');
    this.menuWrapper = document.querySelector('.m-nav-bottom');
    this.menuSearchApp = document.querySelector('#search-app');
    this.mobileMenuTrigger = document.querySelector('.m-m-nav-trigger');
    this.mobileMenuButtons = document.querySelectorAll('.has-dropdown');
    this.mobileMenuDropdown = document.querySelector('.m-m-nav-main');
    this.productsFiltersListing = document.querySelector('.m-product-landing-aside');
    this.mobileProductFiltersTrigger = document.querySelector('.m-product-landing-aside-trigger');
    this.toggleButton.addEventListener('click', () => this.toggleSearchActive());
    this.mobileMenuTrigger.addEventListener('click', () => this.toggleMobileMenu());
    if (this.mobileProductFiltersTrigger !== null) this.mobileProductFiltersTrigger.addEventListener('click', () => this.toggleProductsFilters());
    (0, _jquery.default)('.m-m-nav-button.has-dropdown').on('click', function (event) {
      const dropdown = (0, _jquery.default)(this).find('+ .m-m-nav-dropdown');
      (0, _jquery.default)(dropdown).hasClass('active') ? (0, _jquery.default)(dropdown).removeClass('active') : (0, _jquery.default)(dropdown).addClass('active');
    });
  }

  toggleProductsFilters() {
    const isActive = this.mobileProductFiltersTrigger.classList.contains('active');

    if (isActive) {
      this.mobileProductFiltersTrigger.classList.remove('active');
      this.productsFiltersListing.classList.remove('active'); // this.menuWrapper.classList.add('return');
    } else {
      // this.menuWrapper.classList.remove('return');
      this.mobileProductFiltersTrigger.classList.add('active');
      this.productsFiltersListing.classList.add('active');
    }
  }

  toggleMobileMenu() {
    const isActive = this.mobileMenuTrigger.classList.contains('tcon-transform');

    if (isActive) {
      this.mobileMenuTrigger.classList.remove('tcon-transform');
      this.mobileMenuDropdown.classList.remove('active');
    } else {
      this.mobileMenuTrigger.classList.add('tcon-transform');
      this.mobileMenuDropdown.classList.add('active');
    }
  }

  toggleSearchActive() {
    const isActive = this.menuWrapper.classList.contains('active');

    if (isActive) {
      this.menuWrapper.classList.remove('active');
      this.menuWrapper.classList.add('return');
      this.menuSearchApp.classList.add('active');
      this.menuSearchApp.classList.remove('return');
      this.searchButton.classList.remove('hide_btn');
      this.closeButton.classList.add('hide_btn');
    } else {
      this.menuWrapper.classList.remove('return');
      this.menuWrapper.classList.add('active');
      this.menuSearchApp.classList.add('return');
      this.menuSearchApp.classList.remove('active');
      this.searchButton.classList.add('hide_btn');
      this.closeButton.classList.remove('hide_btn');
      setTimeout(function () {
        (0, _jquery.default)('#autosuggest input').get(0).focus();
      }, 250);
    }
  }

}

var _default = MainNavigation;
exports.default = _default;