"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: () => ({
    selectedApplication: ""
  }),
  props: {
    img: {
      type: String,
      default: ''
    },
    title: {
      type: String
    },
    selected: {
      type: Boolean
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {},
  beforeMount: function () {},
  methods: {}
};
exports.default = _default;