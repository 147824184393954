"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _houseCottage = _interopRequireDefault(require("./house-cottage.vue"));

var _houseSmall = _interopRequireDefault(require("./house-small.vue"));

var _houseAverage = _interopRequireDefault(require("./house-average.vue"));

var _houseLarge = _interopRequireDefault(require("./house-large.vue"));

var _houseMansion = _interopRequireDefault(require("./house-mansion.vue"));

var _pressureLow = _interopRequireDefault(require("./pressure-low.vue"));

var _pressureAverage = _interopRequireDefault(require("./pressure-average.vue"));

var _pressureHigh = _interopRequireDefault(require("./pressure-high.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    HouseCottage: _houseCottage.default,
    HouseSmall: _houseSmall.default,
    HouseAverage: _houseAverage.default,
    HouseLarge: _houseLarge.default,
    HouseMansion: _houseMansion.default,
    PressureLow: _pressureLow.default,
    PressureAverage: _pressureAverage.default,
    PressureHigh: _pressureHigh.default
  },
  data: () => ({
    selectedApplication: ""
  }),
  props: {
    img: {
      type: String,
      default: ''
    },
    title: {
      type: String
    },
    selected: {
      type: Boolean
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
};
exports.default = _default;