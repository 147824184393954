"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _arrowNext = _interopRequireDefault(require("./common/arrow-next.vue"));

var _arrowPrevious = _interopRequireDefault(require("./common/arrow-previous.vue"));

var _chevron = _interopRequireDefault(require("./common/chevron.vue"));

var _checkboxSelect = _interopRequireDefault(require("./findAProduct/checkboxSelect.vue"));

var _imageTextSelect = _interopRequireDefault(require("./findAProduct/imageTextSelect.vue"));

var _resultCard = _interopRequireDefault(require("./findAProduct/result-card.vue"));

var _noResults = _interopRequireDefault(require("./findAProduct/no-results.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'find-a-product',
  components: {
    CheckboxSelect: _checkboxSelect.default,
    ImageTextSelect: _imageTextSelect.default,
    ArrowNext: _arrowNext.default,
    ArrowPrevious: _arrowPrevious.default,
    ResultCard: _resultCard.default,
    Chevron: _chevron.default,
    NoResults: _noResults.default
  },
  props: {
    matrix: Array,
    application: {
      type: String,
      default: "garden"
    },
    urlCountryPrefix: {
      type: String,
      default: '/au/'
    },
    itemCount: {
      type: Number,
      default: 10
    },
    dealerMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    wgtag: false,
    summary: true,
    question: 1,
    questions: {
      garden: {
        1: 0,
        2: 0
      },
      pressure: {},
      rain: {
        1: 0,
        2: 0,
        3: 0,
        4: 0
      }
    },
    quizCompleted: false,
    page: 1
  }),

  created() {
    if (window && window.gtag) {
      this.wgtag = window.gtag;
    }
  },

  computed: {
    gaEventCategory: function () {
      if (this.application === 'garden') {
        return "Pump Selector - Garden Only";
      } else {
        return "Pump Selector - Mains/Rainwater";
      }
    },
    currentAnswers: function () {
      return this.questions[this.application];
    },
    currentMatrix: function () {
      return this.matrix;
    },
    result: function () {
      let self = this;
      return self.currentMatrix.filter(matrix => _lodash.default.isEqual(matrix.answers, self.currentAnswers));
    },
    results: function () {
      return this.result && this.result[0] && this.result[0].result; // there is no multiple correct answers only one.
    },
    displayStart: function () {
      return (this.page - 1) * this.itemCount;
    },
    displayEnd: function () {
      return this.displayStart + this.itemCount;
    },
    pageCount: function () {
      return Math.round(this.results.length / this.itemCount);
    }
  },
  beforeMount: function () {},
  methods: {
    restartQuiz() {
      if (this.wgtag) {
        this.wgtag('event', 'click', {
          'event_category': this.gaEventCategory,
          'event_action': "RestartQuiz",
          'event_label': `Question ${this.question}`
        });
      }

      this.question = 1;
    },

    clockify(text) {
      if (typeof text == "number") {
        if (text < 10) {
          return `0${text}`;
        }
      }

      if (text.length === 1) {
        return `0${text}`;
      }

      return text;
    },

    renderPage: function (pageNum) {
      window.scrollTo(0, 0);
      this.page = pageNum;
    },
    updateAnswer: function (application, question, answer, callback) {
      this.questions[application][question] = answer;

      if (callback) {
        callback();
      }

      ;
    },
    previous: function () {
      this.question = this.question - 1;
    },
    next: function (quizCompleted = false, cat, action) {
      this.question = this.question + 1;
      if (quizCompleted) this.quizCompleted = true;

      if (this.wgtag) {
        if (quizCompleted) {
          this.wgtag('event', 'click', {
            'event_category': cat,
            'event_action': action,
            'event_label': 'Submit'
          });
          this.wgtag('event', 'click', {
            'event_category': cat,
            'event_action': "Completed",
            'event_label': 'Completed'
          });
        } else {
          this.wgtag('event', 'click', {
            'event_category': cat,
            'event_action': action,
            'event_label': 'Next'
          });
        }
      }
    }
  }
};
exports.default = _default;