"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _paginateResults = _interopRequireDefault(require("./paginate-results.vue"));

var _vueSelect = _interopRequireDefault(require("@christoshrousis/vue-select"));

var _utils = require("../utils");

var _listItem = _interopRequireDefault(require("./search/listItem.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'search',
  extends: _paginateResults.default,
  components: {
    ListItem: _listItem.default,
    vSelect: _vueSelect.default
  },
  props: {
    prefix: String,
    productTypes: Array,
    productApplications: Array,
    news: Array,
    caseStudies: Array,
    products: Array,
    translations: Object,
    itemCount: {
      type: Number,
      default: 15
    }
  },
  mounted: function () {
    // Update Quicklinks.
    const quickLinks = document.getElementById('quick-links-section');
    this.quickLinks = quickLinks.querySelector('.m-cs-landing-hero__quick-links').innerHTML;
    quickLinks.remove(); // Set Query to search.

    const queryString = (0, _utils.getQueryValue)("query");

    if (queryString) {
      this.searchString = queryString.replace(/\%20/g, " ");
    }
  },
  data: () => ({
    page: 1,
    quickLinks: "",
    productExplore: [{
      id: 1,
      name: "Product",
      key: "product"
    }, {
      id: 2,
      name: "Case Study",
      key: "case-study"
    }, {
      id: 3,
      name: "News",
      key: "news"
    }],
    productExploreSelected: undefined,
    productTypeSelected: undefined,
    productApplicationSelected: undefined,
    searchString: '',
    response: ''
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.resultsCount / this.itemCount);
    },

    filteredResults() {
      let result = this.filteredByQuery;
      if (this.productExploreSelected) result = this.filterByExplore(result, this.productExploreSelected);
      if (this.productTypeSelected) result = this.filterByType(result, this.productTypeSelected);
      if (this.productApplicationSelected) result = this.filterByApplication(result, this.productApplicationSelected);
      this.page = 1;
      return result;
    },

    filteredByQuery() {
      if (this.searchStringIsValid) {
        return this.results && this.results.filter(result => {
          if (result.queryString) {
            return result.queryString.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
          } else {
            return false;
          }
        });
      } else {
        return this.results;
      }
    },

    searchStringIsValid() {
      return this.searchString && this.searchString !== "";
    },

    results() {
      return this.news.concat(this.caseStudies, this.products);
    },

    resultsCount() {
      return this.results && this.filteredResults && this.filteredResults.length;
    }

  },
  methods: {
    filterByExplore: (collection, filter) => filter ? collection.filter(r => r.type === filter) : collection,
    filterByType: (collection, filter) => filter ? collection.filter(r => r.productTypes.includes(filter)) : collection,
    filterByApplication: (collection, filter) => filter ? collection.filter(r => r.productApplications.includes(filter)) : collection
  }
};
exports.default = _default;