"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _arrowLinkButton = _interopRequireDefault(require("./common/arrow-link-button.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowLink: _arrowLinkButton.default
  },

  data() {
    return {
      isWindowOpen: false,
      windowHasLoaded: false,
      markers: [],
      windowMode: false,
      heroImage: null,
      hotspotsContainer: null,
      hotspotsMarkerContainer: null
    };
  },

  props: {
    heroImageSource: String,
    heroImageAlt: String,
    markerData: Array
  },
  created: function () {
    window.addEventListener('load', () => {
      this.windowHasLoaded = true;
      this.heroImage = this.$refs.heroImage;
      this.hotspotsContainer = this.$refs.hotspotsContainer;
      this.hotspotsMarkerContainer = this.$refs.hotspotsMarkerContainer;
    });
    this.resizeHandler();
    window.addEventListener('resize', (0, _lodash.debounce)(() => this.resizeHandler(), 150));
    this.markers = this.markerData.map(marker => {
      marker.active = false;
      return marker;
    });
  },
  computed: {
    hotspotsMarkerContainerHeight() {
      if (!this.hotspotsMarkerContainer) return;
      const containerHeight = this.getHeight(this.hotspotsMarkerContainer);
      const imageHeight = this.getHeight(this.heroImage);
      const isContainerTallerThanImage = containerHeight > imageHeight;

      if (isContainerTallerThanImage) {
        return `${imageHeight}px`;
      } else {
        return "100%";
      }
    },

    hasMarkers() {
      return this.markerData && this.markerData.length > 0;
    },

    currentActiveMarker() {
      let activeMarker = false;
      this.markers.forEach(marker => {
        if (marker.active) {
          activeMarker = marker;
        }
      });
      return activeMarker;
    }

  },
  methods: {
    getHeight(el) {
      return parseFloat(getComputedStyle(el, null).height.replace("px", ""));
    },

    resizeHandler() {
      if (window.innerWidth < 900) {
        this.windowMode = true;
      } else {
        this.windowMode = false;
      } // this.checkHeights();

    },

    // checkHeights() {
    //   this.resetHeights()
    //   if(!(window.innerWidth < 900)) {
    //     this.setHeights()
    //   }
    // },
    // setHeights() {
    //   this.heroProducts.each(function() {
    //     const $productContainer = $(this).find(LHP_CONTAINER)
    //     const $image = $(this).find(LHP_IMAGE)
    //     if($productContainer.height() > $image.height()) {
    //       $productContainer.height($image.height());
    //     }
    //   })
    // },
    // resetHeights() {
    //   this.heroProducts.each(function() {
    //     $(this).find(LHP_CONTAINER).height('100%')
    //   })
    // },
    flipHotspotState(index) {
      if (window.innerWidth < 900) {
        const currentState = this.markers[index].active;
        this.markers.forEach(marker => marker.active = false);

        if (!currentState) {
          this.markers[index].active = true;
        }

        ;
      } else {
        this.markers[index].active = !this.markers[index].active;
      }
    },

    mouseOverHandler(index) {
      if (!(window.innerWidth < 900)) {
        if (!this.markers[index].active) {
          this.markers[index].active = true;
        }
      }
    },

    mouseLeaveHandler(index) {
      if (!(window.innerWidth < 900)) {
        if (this.markers[index].active) {
          this.markers[index].active = false;
        }
      }
    }

  }
};
exports.default = _default;