"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _arrowLinkButton = _interopRequireDefault(require("../common/arrow-link-button.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ArrowLink: _arrowLinkButton.default
  },
  props: {
    title: String,
    description: String,
    link: String,
    type: String,
    img: String,
    readMore: String,
    skus: Array
  }
};
exports.default = _default;