"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vue-range-component/dist/vue-range-slider.css");

var _vueRangeComponent = _interopRequireDefault(require("vue-range-component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import VueSlider from 'vue-slider-component'
//import 'vue-slider-component/theme/default.css'
var _default = {
  data() {
    return {
      value: 0,
      width: 'auto',
      height: 4,
      tooltipStyle: {
        'display': 'none'
      }
    };
  },

  computed: {},
  props: {
    startValue: {
      type: Number,
      Default: 0
    },
    title: {
      type: String,
      Default: 'Slide bar to enter value'
    },
    max: {
      type: Number,
      Default: 100
    },
    min: {
      type: Number,
      Default: 0
    }
  },
  components: {
    VueSlider: _vueRangeComponent.default
  },
  created: function () {
    this.value = this.startValue; //>0?this.startValue:1;
  },
  methods: {
    processData: function () {//this.$emit('slideHandler', this.$refs.numberSlider.getValue());
    }
  },
  watch: {
    value: function () {
      this.$emit('slideHandler', this.value);
    }
  }
};
exports.default = _default;