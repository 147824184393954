"use strict";

var _lodash = require("lodash");

var _jquery = _interopRequireDefault(require("jquery"));

require("slick-carousel");

require("./lib-autocomplete");

require("jquery.scrollto");

var _responsiveSliderFactory = _interopRequireDefault(require("./responsiveSliderFactory"));

var _mainNavigation = _interopRequireDefault(require("./mainNavigation"));

var _matchHeights = _interopRequireDefault(require("./matchHeights"));

var _productSlider = _interopRequireDefault(require("./productSlider"));

var _pushImagesIntoBackground = _interopRequireDefault(require("./pushImagesIntoBackground"));

var _formHandler = _interopRequireDefault(require("./formHandler"));

var _modal = _interopRequireDefault(require("./modal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _jquery.default)(document).ready(function () {
  var $blockSlider = (0, _jquery.default)('.block-slider');
  $blockSlider.slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    initialSlide: 0
  });
  var $solutionsSlider = (0, _jquery.default)('.m-solutions-slider');
  var $progressBar = (0, _jquery.default)('.m-solutions-slider-progress');
  $solutionsSlider.slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    initialSlide: 0,
    responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  function yellowifySliderNavIcon(slide) {
    (0, _jquery.default)('.m-solutions-slider-nav a').removeClass('active');
    var activeSlideNav = (0, _jquery.default)('.m-solutions-slider-nav').find(`[data-slide='${slide}']`);
    (0, _jquery.default)(activeSlideNav).addClass('active');
  }

  (0, _jquery.default)('.m-solutions-slider-nav-more').click(function (e) {
    e.preventDefault();
    $solutionsSlider.slick('slickNext');
  });
  (0, _jquery.default)('a[data-slide]').click(function (e) {
    e.preventDefault();
    var slideno = (0, _jquery.default)(this).data('slide');
    $solutionsSlider.slick('slickGoTo', slideno);
  });
  $solutionsSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    if (nextSlide !== 0 && slick.slideCount !== 0 || nextSlide !== 1 && slick.slideCount !== 1) {
      var calc = (nextSlide + 1) / slick.slideCount * 100;
      $progressBar.css('background-size', calc + '% 100%');
      yellowifySliderNavIcon(nextSlide);
    }
  });
  var $openingSlider = (0, _jquery.default)('.m-opening-slider-slides');
  $openingSlider.slick({
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    initialSlide: 0,
    centerPadding: 0,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    fade: true,
    cssEase: 'linear'
  });
  const responsiveSlidersArray = [];
  (0, _lodash.forEach)(['.m-case-study-listing-news-listing', '.m-hero-products-buttons', '.m-whats-new-news-listing', '.m-solutions-listing-news-listing'], className => {
    if ((0, _jquery.default)(className).length > 0) {
      responsiveSlidersArray.push(className);
    }
  }); // Scroll To Element For Warranty Form.

  if ((0, _jquery.default)('.arrow-link-type-warranty').length > 0) {
    (0, _jquery.default)('.arrow-link-type-warranty').on('click', function (e) {
      e.preventDefault();
      (0, _jquery.default)(window).scrollTo((0, _jquery.default)('.pimcore_area_dav-block-contact-form'), 600);
    });
  }

  if ((0, _jquery.default)('#changeRegionForm').length > 0) {
    (0, _jquery.default)('#changeRegionForm select').on('change', function () {
      const $selected = (0, _jquery.default)(this).find(":selected");
      (0, _jquery.default)('#changeRegionForm').attr('action', $selected.data('uri'));
    });
  }

  if ((0, _jquery.default)('.anchor-marker').length > 0) {
    (0, _jquery.default)('.anchor-marker').on('click', function (e) {
      e.preventDefault();
      const target = (0, _jquery.default)(this).data('target');
      (0, _jquery.default)(window).scrollTo((0, _jquery.default)(`#${target}`), {
        duration: 600,
        offset: -200
      });
    });
  }

  window.FormHandler = new _formHandler.default();
  window.ProductSlider = new _productSlider.default();
  window.ResponseSliderFactory = new _responsiveSliderFactory.default(responsiveSlidersArray);
  window.MatchHeights = new _matchHeights.default();
  window.MainNavigation = new _mainNavigation.default();
  window.PushImagesIntoBackground = new _pushImagesIntoBackground.default();
  window.Modal = new _modal.default('open-modal');
  window.Modal = new _modal.default('open-modal-mobile'); // https://snippetlib.com/jquery/replace_all_svg_images_with_inline_svg
  // *  Replace all SVG images with inline SVG *

  (0, _jquery.default)('.m-solutions-slider-nav a img').each(function () {
    var $img = (0, _jquery.default)(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    _jquery.default.get(imgURL, function (data) {
      //  Get the SVG tag, ignore the rest
      var $svg = (0, _jquery.default)(data).find('svg'); //  Add replaced image's ID to the new SVG

      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      } //  Add replaced image's classes to the new SVG


      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      } //  Remove any invalid XML tags as per http:validator.w3.org


      $svg = $svg.removeAttr('xmlns:a'); //  Replace image with new SVG

      $img.replaceWith($svg);
    });
  });
});